.layout-app-mobile .fr-small-tabs {
  padding: 0 0.4rem;
}
.layout-app-mobile .fr-small-tabs::before {
  height: 0.02rem;
}
.layout-app-mobile .fr-small-tabs .tabs-item {
  height: 0.25rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
}
.layout-app-mobile .fr-small-tabs .line-box {
  left: 0.4rem;
  right: 0.4rem;
}
.layout-app-mobile .fr-small-tabs .line {
  height: 0.01rem;
  background: #ffffff;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.2s;
}
