@font-face {
  font-family: 'iconfont';
  /* Project id 4204405 */
  src: url('//at.alicdn.com/t/c/font_4204405_6bis2hjtn3.woff2?t=1692673029568') format('woff2'), url('//at.alicdn.com/t/c/font_4204405_6bis2hjtn3.woff?t=1692673029568') format('woff'), url('//at.alicdn.com/t/c/font_4204405_6bis2hjtn3.ttf?t=1692673029568') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-right:before {
  content: "\e620";
}
.icon-eyes:before {
  content: "\e63d";
}
.icon-time:before {
  content: "\e63e";
}
.icon-check:before {
  content: "\e61f";
}
.icon-desc11:before {
  content: "\e61c";
}
.icon-desc31:before {
  content: "\e61d";
}
.icon-desc21:before {
  content: "\e61e";
}
.icon-desc41:before {
  content: "\e61b";
}
.icon-desc4:before {
  content: "\e611";
}
.icon-desc3:before {
  content: "\e618";
}
.icon-desc1:before {
  content: "\e619";
}
.icon-desc2:before {
  content: "\e61a";
}
.icon-zndg:before {
  content: "\e613";
}
.icon-touzizhe:before {
  content: "\e614";
}
.icon-nrsj:before {
  content: "\e615";
}
.icon-cpcx:before {
  content: "\e612";
}
.icon-yxch:before {
  content: "\e616";
}
.icon-scjd:before {
  content: "\e617";
}
.icon-arrow-up:before {
  content: "\e63c";
}
.icon-wecode:before {
  content: "\e639";
}
.icon-handshake:before {
  content: "\e63a";
}
.icon-headset:before {
  content: "\e63b";
}
.icon-zhihu:before {
  content: "\e636";
}
.icon-weibo:before {
  content: "\e637";
}
.icon-weixin:before {
  content: "\e638";
}
.icon-close:before {
  content: "\e63f";
}
.main-mid-width {
  width: 12rem;
}
.common-module-full {
  width: 100%;
}
.common-module-full.common-module-padding-top {
  padding-top: 0.8rem;
}
.common-module-full.common-module-padding-bottom {
  padding-bottom: 0.8rem;
}
.common-module-1440 {
  width: 100vw;
  max-width: 14.4rem;
  margin: 0 auto;
}
.common-module-1440.common-module-padding-top {
  padding-top: 0.8rem;
}
.common-module-1440.common-module-padding60 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.common-module-box {
  width: 12rem;
  margin: 0 auto;
  padding-top: 0.8rem;
}
.common-module-box.common-module-padding-bottom {
  padding-bottom: 0.8rem;
}
.common-module-box.common-module-padding60 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.common-module-title {
  font-size: 0.34rem;
  font-weight: 600;
  color: #041133;
  line-height: 0.48rem;
  text-align: center;
}
.common-module-title + .common-module-text {
  margin-top: 0.12rem;
}
.common-module-title + .common-module-pic,
.common-module-title + .common-module-row-list,
.common-module-title + .common-module-bot,
.common-module-title + .fr-bg-icon-tabs {
  margin-top: 0.4rem;
}
.common-module-text {
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.45);
  text-align: center;
  white-space: pre-line;
}
.common-module-text + .common-module-pic,
.common-module-text + .common-module-row-list,
.common-module-text + .common-module-bot,
.common-module-text + .fr-bg-icon-tabs {
  margin-top: 0.4rem;
}
.common-module-pic {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.common-module-row-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.common-module-row-list.cards .col {
  border-radius: 0.1rem;
  border: 0.01rem solid rgba(4, 17, 51, 0.1);
  background: #ffffff;
}
.common-module-row-list.cards-shadow .col {
  border-radius: 0.1rem;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.16rem 0 rgba(4, 17, 51, 0.1);
}
.common-module-row-list.gap3 {
  gap: 0.3rem;
}
.common-module-row-list.gap3.col3 .col {
  width: calc(100% / 3 - 0.3rem * 2 / 3);
}
.common-module-row-list.gap4 {
  gap: 0.4rem;
}
.common-module-row-list.gap4.col3 .col {
  width: calc(100% / 3 - 0.4rem * 2 / 3);
}
.common-module-row-list.gap24 {
  gap: 0.24rem;
}
.common-module-row-list.gap24.col5 .col {
  width: calc(100% / 5 - 0.24rem * 4 / 5);
}
.common-module-row-list.gap25 {
  gap: 0.25rem;
}
.common-module-row-list.gap25.col5 .col {
  width: calc(100% / 5 - 0.25rem * 4 / 5);
}
.common-module-row-list.gap25.col4 .col {
  width: calc(100% / 4 - 0.25rem * 3 / 4);
}
.common-module-row-list.gap3 {
  gap: 0.3rem;
}
.common-module-row-list.gap3.col3 .col {
  width: calc(100% / 3 - 0.3rem * 2 / 3);
}
.common-module-row-list.gap5 {
  gap: 0.5rem;
}
.common-module-row-list.gap5.col2 .col {
  width: calc(100% / 2 - 0.5rem / 2);
}
.common-module-row-list.gap16 {
  gap: 0.16rem;
}
.common-module-row-list.gap16.col2 .col {
  width: calc(100% / 2 - 0.16rem / 2);
  display: block;
}
.common-module-row-list.gap12 {
  gap: 0.12rem;
}
.common-module-row-list.gap12.col5 .col {
  width: calc(100% / 5 - 0.12rem * 4 / 5);
  display: block;
}
.common-module-row-list .col-top-icon {
  height: 0.8rem;
  margin-bottom: 0.12rem;
}
.common-module-row-list .col-top-left-icon {
  height: 0.5rem;
  margin-bottom: 0.16rem;
}
.common-module-row-list .col-title-bg {
  color: #041133;
}
.common-module-row-list .col-title-bg,
.common-module-row-list .col-title-bg span {
  font-size: 0.28rem;
  font-weight: 500;
  line-height: 0.42rem;
}
.common-module-row-list .col-title-bg span {
  color: #2352d8;
  display: block;
}
.common-module-row-list .col-title {
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #041133;
  text-align: center;
}
.common-module-row-list .col-title.left {
  text-align: left;
}
.common-module-row-list .col-title + .col-text {
  margin-top: 0.08rem;
}
.common-module-row-list .col-title + .col-line {
  margin-top: 0.23rem;
}
.common-module-row-list .col-title + .col-border-box {
  margin-top: 0.08rem;
}
.common-module-row-list .col-line {
  height: 0.01rem;
  border: 0.01rem dashed rgba(4, 17, 51, 0.1);
}
.common-module-row-list .col-text {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: #9e9e9e;
  white-space: pre-line;
  text-align: center;
}
.common-module-row-list .col-text.left {
  text-align: left;
}
.common-module-row-list .col-border-box {
  border-radius: 0.04rem;
  padding: 0.02rem 0.08rem;
  background: rgba(35, 82, 216, 0.05);
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: #2352d8;
}
.common-module-row-list .col-border-box + .col-text {
  margin-top: 0.2rem;
}
.common-module-row-list .col-list {
  margin-top: 0.24rem;
}
.common-module-row-list .col-list li {
  padding-top: 0.2rem;
  color: #041133;
  display: flex;
  font-size: 0.14rem;
  line-height: 0.22rem;
}
.common-module-row-list .col-list li:first-child {
  padding-top: 0;
}
.common-module-row-list .col-list li .num {
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.2);
  width: 0.27rem;
  font-weight: 500;
  font-size: 0.16rem;
}
.common-module-row-list .col-list li .text {
  font-size: 0.16rem;
  color: #8a8d94;
  padding: 0.02rem 0;
  flex: 1;
}
.common-module-row-list .col-list li .iconfont {
  color: #32bfa1;
  width: 0.3rem;
}
.common-module-row-list .col-list li .iconfont + span {
  flex: 1;
  font-size: 0.14rem;
}
.common-module-row-list .col-list.no-list-style li .num {
  display: none;
}
.common-module-bot {
  display: flex;
  justify-content: center;
}
.common-banner-top {
  height: 0.6rem;
}
.common-banner-top + .common-banner {
  padding-top: 0;
}
.common-banner {
  width: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #eff8ff;
  padding-top: 0.6rem;
  background-size: auto 100%;
}
.common-banner .pic {
  width: 100%;
}
.common-banner .common-banner-bg-black {
  height: 100%;
  width: 100%;
  background: linear-gradient(270deg, rgba(13, 8, 8, 0) 0%, rgba(8, 9, 13, 0.8) 70%, rgba(8, 9, 13, 0.9) 109%);
}
.common-banner .common-banner-main {
  width: 12rem;
  margin: 0 auto;
  height: 6.4rem;
}
.common-banner .common-banner-box {
  padding-top: 1.8rem;
}
.common-banner .common-banner-title-sm {
  color: #041133;
  display: flex;
  align-items: flex-end;
}
.common-banner .common-banner-title-sm,
.common-banner .common-banner-title-sm .normal {
  font-size: 0.44rem;
  font-weight: 500;
}
.common-banner .common-banner-title-sm.white {
  color: #fff;
}
.common-banner .common-banner-title-sm .big {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.12rem;
  padding-right: 0.2rem;
  position: relative;
}
.common-banner .common-banner-title-sm .big::after {
  content: attr(data-text);
  position: relative;
  z-index: 1;
}
.common-banner .common-banner-title-sm .big::before {
  width: 2rem;
  height: 0.25rem;
  background: linear-gradient(270deg, rgba(35, 82, 216, 0) 0%, #2352d8 100%);
  position: absolute;
  left: -0.03rem;
  bottom: 0.11rem;
  content: "";
  display: block;
}
.common-banner .common-banner-title-sm .normal {
  padding-bottom: 0.1rem;
}
.common-banner .common-banner-title {
  white-space: pre-line;
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 0.6rem;
  color: #041133;
}
.common-banner .common-banner-title + .common-banner-line {
  margin-top: 0.4rem;
}
.common-banner .common-banner-title + .common-banner-text {
  margin-top: 0.24rem;
}
.common-banner .common-banner-title + .common-banner-text-sm {
  margin-top: 0.31rem;
}
.common-banner .common-banner-title + .common-banner-text-md {
  margin-top: 0.31rem;
}
.common-banner .common-banner-text {
  white-space: pre-line;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: rgba(4, 17, 51, 0.65);
}
.common-banner .common-banner-text-md {
  white-space: pre-line;
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.8);
  opacity: 0.7;
}
.common-banner .common-banner-text-sm {
  white-space: pre-line;
  font-size: 0.12rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.65);
  opacity: 0.7;
}
.common-banner .common-banner-line {
  width: 0.4rem;
  height: 0.06rem;
  background: #2352d8;
}
.common-banner .common-banner-line + .common-banner-text {
  margin-top: 0.24rem;
}
.layout-app-mobile .main-mid-width {
  width: 100vw;
}
.layout-app-mobile .common-module-full.common-module-padding-top {
  padding-top: 0.4rem;
}
.layout-app-mobile .common-module-full.common-module-padding-bottom {
  padding-bottom: 0.4rem;
}
.layout-app-mobile .common-module-1440.common-module-padding-top {
  padding-top: 0.4rem;
}
.layout-app-mobile .common-module-1440 .common-module-padding60 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.layout-app-mobile .common-module-box {
  width: 100%;
  padding-top: 0.4rem;
}
.layout-app-mobile .common-module-box.common-module-padding-bottom {
  padding-bottom: 0.4rem;
}
.layout-app-mobile .common-module-box .common-module-padding60 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.layout-app-mobile .common-module-title {
  font-size: 0.2rem;
  line-height: 0.28rem;
  padding: 0 0.24rem;
}
.layout-app-mobile .common-module-title + .common-module-text {
  margin-top: 0.08rem;
}
.layout-app-mobile .common-module-title + .common-module-pic,
.layout-app-mobile .common-module-title + .common-module-row-list,
.layout-app-mobile .common-module-title + .common-module-bot,
.layout-app-mobile .common-module-title + .fr-bg-icon-tabs {
  margin-top: 0.2rem;
}
.layout-app-mobile .common-module-text {
  padding: 0 0.24rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
  white-space: normal;
}
.layout-app-mobile .common-module-text + .common-module-pic,
.layout-app-mobile .common-module-text + .common-module-row-list,
.layout-app-mobile .common-module-text + .common-module-bot,
.layout-app-mobile .common-module-text + .fr-bg-icon-tabs {
  margin-top: 0.2rem;
}
.layout-app-mobile .common-module-pic {
  width: 100%;
}
.layout-app-mobile .common-module-row-list.gap3 {
  gap: 0.2rem;
  padding: 0 0.2rem;
}
.layout-app-mobile .common-module-row-list.gap3.col3 .col {
  width: calc(100% / 2 - 0.2rem / 2);
}
.layout-app-mobile .common-module-row-list.gap4 {
  gap: 0.2rem;
  padding: 0 0.2rem;
}
.layout-app-mobile .common-module-row-list.gap4.col3 .col {
  width: calc(100% / 2 - 0.2rem / 2);
}
.layout-app-mobile .common-module-row-list.gap24 {
  gap: 0.12rem;
}
.layout-app-mobile .common-module-row-list.gap24.col5 .col {
  width: calc(100% / 3 - 0.12rem * 2 / 3);
}
.layout-app-mobile .common-module-row-list.gap25 {
  gap: 0.125rem;
}
.layout-app-mobile .common-module-row-list.gap25.col5 .col {
  width: calc(100% / 3 - 0.125rem * 2 / 3);
}
.layout-app-mobile .common-module-row-list.gap25.col4 .col {
  width: calc(100% / 2 - 0.125rem * 1 / 2);
}
.layout-app-mobile .common-module-row-list.gap5 {
  gap: 0;
}
.layout-app-mobile .common-module-row-list.gap5.col2 .col {
  width: 100%;
}
.layout-app-mobile .common-module-row-list.gap16 {
  gap: 0.08rem;
}
.layout-app-mobile .common-module-row-list.gap16.col2 .col {
  width: calc(100% / 2 - 0.08rem / 2);
  display: block;
}
.layout-app-mobile .common-module-row-list.gap12 {
  gap: 0.06rem;
}
.layout-app-mobile .common-module-row-list.gap12.col5 .col {
  width: calc(100% / 5 - 0.06rem * 4 / 5);
  display: block;
}
.layout-app-mobile .common-module-row-list .col-top-icon {
  height: 0.4rem;
  margin-bottom: 0.06rem;
  display: block;
}
.layout-app-mobile .common-module-row-list .col-top-left-icon {
  height: 0.25rem;
  margin-bottom: 0.08rem;
  display: block;
}
.layout-app-mobile .common-module-row-list .col-title-bg,
.layout-app-mobile .common-module-row-list .col-title-bg span {
  font-size: 0.18rem;
  line-height: 0.24rem;
}
.layout-app-mobile .common-module-row-list .col-title {
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.layout-app-mobile .common-module-row-list .col-title + .col-text {
  margin-top: 0.08rem;
}
.layout-app-mobile .common-module-row-list .col-title + .col-line {
  margin-top: 0.12rem;
}
.layout-app-mobile .common-module-row-list .col-title + .col-border-box {
  margin-top: 0.04rem;
}
.layout-app-mobile .common-module-row-list .col-text {
  font-size: 0.12rem;
  line-height: 0.2rem;
  white-space: normal;
}
.layout-app-mobile .common-module-row-list .col-border-box {
  padding: 0.02rem 0.04rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #2352d8;
}
.layout-app-mobile .common-module-row-list .col-border-box + .col-text {
  margin-top: 0.1rem;
}
.layout-app-mobile .common-module-row-list .col-list {
  margin-top: 0.12rem;
}
.layout-app-mobile .common-module-row-list .col-list li {
  padding-top: 0.1rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
}
.layout-app-mobile .common-module-row-list .col-list li .num {
  line-height: 0.2rem;
  width: 0.2rem;
  font-size: 0.12rem;
}
.layout-app-mobile .common-module-row-list .col-list li .text {
  font-size: 0.12rem;
  padding: 0;
}
.layout-app-mobile .common-module-row-list .col-list li .iconfont {
  width: 0.15rem;
  font-size: 0.1rem;
}
.layout-app-mobile .common-module-row-list .col-list li .iconfont + span {
  flex: 1;
  font-size: 0.12rem;
}
.layout-app-mobile .common-banner-top {
  height: 0.48rem;
}
.layout-app-mobile .common-banner-top + .common-banner {
  padding-top: 0;
}
.layout-app-mobile .common-banner {
  width: 100%;
  padding-top: 0.48rem;
  background-position: bottom center;
}
.layout-app-mobile .common-banner .common-banner-main {
  width: 100%;
  padding: 0 0.24rem;
  height: 3rem;
}
.layout-app-mobile .common-banner .common-banner-box {
  padding-top: 0.9rem;
}
.layout-app-mobile .common-banner .common-banner-title-sm,
.layout-app-mobile .common-banner .common-banner-title-sm .normal {
  font-size: 0.2rem;
}
.layout-app-mobile .common-banner .common-banner-title-sm .big {
  font-size: 0.4rem;
  line-height: 0.56rem;
  padding-right: 0.1rem;
}
.layout-app-mobile .common-banner .common-banner-title-sm .big::before {
  width: 1rem;
  height: 0.125rem;
  left: -0.015rem;
  bottom: 0.055rem;
}
.layout-app-mobile .common-banner .common-banner-title-sm .normal {
  padding-bottom: 0.1rem;
}
.layout-app-mobile .common-banner .common-banner-title {
  font-size: 0.24rem;
  line-height: 0.3rem;
}
.layout-app-mobile .common-banner .common-banner-title + .common-banner-line {
  margin-top: 0.2rem;
}
.layout-app-mobile .common-banner .common-banner-title + .common-banner-text {
  margin-top: 0.12rem;
}
.layout-app-mobile .common-banner .common-banner-title + .common-banner-text-sm {
  margin-top: 0.12rem;
}
.layout-app-mobile .common-banner .common-banner-title + .common-banner-text-md {
  margin-top: 0.12rem;
}
.layout-app-mobile .common-banner .common-banner-text {
  font-size: 0.12rem;
  line-height: 0.18rem;
}
.layout-app-mobile .common-banner .common-banner-text-md {
  font-size: 0.14rem;
  line-height: 0.2rem;
}
.layout-app-mobile .common-banner .common-banner-text-sm {
  font-size: 0.12rem;
  line-height: 0.18rem;
}
.layout-app-mobile .common-banner .common-banner-line {
  width: 0.2rem;
  height: 0.03rem;
}
.layout-app-mobile .common-banner .common-banner-line + .common-banner-text {
  margin-top: 0.12rem;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.16rem;
  box-sizing: border-box;
  text-decoration: none;
}
html {
  font-size: 100px;
  /* 不可以转换成rem */
}
body {
  font-size: 0.16rem;
  font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}
@font-face {
  font-family: "zihun2hao-liliangcuheiti-Regular";
  src: url("https://staging.frontis.top/font-type.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINAlternate-bold";
  src: url("./font/DIN Alternate Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
p {
  margin-bottom: 0;
  margin: 0;
}
h5 {
  margin-bottom: 0;
}
::-webkit-scrollbar {
  width: 0.06rem;
  height: 0.06rem;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #dedee4;
  border-radius: 0.04rem;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
ol,
ul,
dl {
  margin-bottom: 0;
}
a {
  text-decoration: none;
  outline: none;
  color: #041133;
}
.common-primary-btn {
  border-radius: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2352d8;
  color: #ffffff;
  cursor: pointer;
  height: 0.4rem;
  font-size: 0.16rem;
  padding: 0 0.3rem;
}
.common-primary-btn.big {
  font-size: 0.2rem;
  padding: 0 0.64rem;
  font-weight: 500;
  line-height: 0.28rem;
  height: 0.6rem;
}
.common-ghost-primary-btn {
  height: 0.36rem;
  padding: 0 0.24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.04rem;
  border: 1px solid #2352d8;
  color: #2352d8;
  font-size: 0.16rem;
  line-height: 0.24rem;
  cursor: pointer;
}
input,
textarea,
keygen,
select,
button {
  font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}
