.ai-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFang SC;
}
.ai-main .w1200 {
  max-width: 12rem;
  margin-bottom: 0.8rem;
}
.ai-main .w1200 .ai-box {
  display: flex;
  margin-top: 0.4rem;
}
.ai-main .w1200 .title {
  text-align: center;
  margin-top: 0.8rem;
}
.ai-main .w1200 h3 {
  font-size: 0.34rem;
  font-weight: 600;
  margin-bottom: 0.32rem;
}
.ai-main .w1200 .left {
  width: 3.29rem;
  padding-top: 1.24rem;
}
.ai-main .w1200 .right {
  flex: 1 1;
}
.ai-main .w1200 .right img {
  width: 100%;
}
.ai-main .w1200 li {
  font-size: 0.18rem;
  font-weight: normal;
  line-height: 0.28rem;
  letter-spacing: 0em;
  margin-bottom: 0.2rem;
  /* 远山黑/100 */
  color: #041133;
}
.ai-main .w1200 li::before {
  content: "";
  display: block;
  width: 0.06rem;
  height: 0.06rem;
  float: left;
  border-radius: 100%;
  background: rgba(4, 17, 51, 0.3);
  margin-top: 0.12rem;
  margin-right: 0.12rem;
}
