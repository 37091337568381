.immediately-media {
  height: 0.6rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-image: url("./images/bgImg.png");
  background-size: 100% 100%;
}
.immediately-media p {
  display: flex;
  font-size: 0.12rem;
  font-weight: 500;
  line-height: 0.26rem;
  text-align: center;
  letter-spacing: 0em;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
.immediately-media .product-btn-mobile {
  width: 0.64rem;
  line-height: 0.18rem;
  margin: 0;
  height: 0.22rem;
  margin-left: 0.1rem;
  border-radius: 1.04px;
}
.immediately-media .product-btn-mobile span {
  font-size: 0.1rem;
}
