.product-highlights.product-highlights-mobile {
  width: 100% !important;
  padding: 0 0.24rem !important;
}
.product-highlights.product-highlights-mobile h2 {
  padding: 0.4rem 0 0.2rem !important;
  font-size: 0.18rem !important;
}
.product-highlights.product-highlights-mobile .lights-wrapper {
  position: relative;
  width: 100%;
  height: 0.64rem;
}
.product-highlights.product-highlights-mobile .lights-wrapper .rect {
  position: absolute;
  right: 0;
  bottom: 6px;
  width: 0.54rem;
  height: 0.64rem;
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.product-highlights.product-highlights-mobile .lights-box {
  width: 100%;
  padding-right: 0.24rem;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.product-highlights.product-highlights-mobile .lights-box::-webkit-scrollbar {
  display: none;
}
.product-highlights.product-highlights-mobile .lights-box .light-item {
  margin-right: 0.04rem !important;
  padding-bottom: 0.08rem !important;
  text-align: center;
  font-size: 0.12rem !important;
  line-height: 0.16rem !important;
  min-width: 0.9rem;
}
.product-highlights.product-highlights-mobile .lights-box .light-item img {
  width: 0.32rem !important;
  height: 0.32rem !important;
  margin-bottom: 0.04rem !important;
}
.product-highlights.product-highlights-mobile .lights-box .light-item::after {
  width: 0.36rem !important;
}
.product-highlights.product-highlights-mobile .light-content {
  position: relative;
  margin-top: 0.24rem !important;
  flex-direction: column;
}
.product-highlights.product-highlights-mobile .light-content .left {
  padding-top: 0 !important;
  width: auto !important;
}
.product-highlights.product-highlights-mobile .light-content .left .title {
  margin-bottom: 0.08rem !important;
  font-size: 0.16rem !important;
}
.product-highlights.product-highlights-mobile .light-content .left .texts-box .text {
  font-size: 0.12rem !important;
  color: #041133;
  line-height: 0.2rem;
  margin-bottom: 0.1rem;
}
.product-highlights.product-highlights-mobile .light-content .left .texts-box .text span {
  margin-right: 0.08rem !important;
  font-size: 0.12rem !important;
}
.product-highlights.product-highlights-mobile .light-content .left .product-btn-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #2352D8;
  border: none;
  border-radius: 0.04rem;
  margin: 0.12rem 0 0.16rem;
  font-size: 0.1rem;
  width: 1.2rem;
  height: 0.36rem;
  zoom: 0.8;
}
.product-highlights.product-highlights-mobile .light-content .left .product-btn-mobile::before {
  display: none;
}
.product-highlights.product-highlights-mobile .light-content .left .product-btn-mobile:active {
  color: #fff;
}
.product-highlights.product-highlights-mobile .light-content .left .product-btn-mobile:hover {
  color: #fff;
}
.product-highlights.product-highlights-mobile .light-content .right {
  width: 100% !important;
  height: auto !important;
  margin-left: 0 !important;
  margin-top: 0.16rem;
}
