.articles-component .article-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
}
.articles-component .article-tabs .article-tab {
  margin-right: 0.4rem;
  font-size: 0.2rem;
  line-height: 0.48rem;
  color: #041133;
  cursor: pointer;
}
.articles-component .article-tabs .article-tab.active {
  position: relative;
  font-weight: 500;
}
.articles-component .article-tabs .article-tab.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.5rem;
  height: 0.02rem;
  background: #2352d8;
}
.articles-component .article-wrapper {
  padding-bottom: 0.4rem;
}
.articles-component .article-wrapper .article-empty {
  text-align: center;
  color: rgba(4, 17, 51, 0.45);
  height: calc(100vh - 4.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.articles-component .article-wrapper .article-lists {
  padding: 0 1.2rem;
}
.articles-component .article-wrapper .article-lists .article-item {
  display: flex;
  padding: 0.24rem;
  cursor: pointer;
}
.articles-component .article-wrapper .article-lists .article-item .cover {
  background: no-repeat center center;
  background-size: contain;
  border-radius: 0.04rem;
  height: 1.6rem;
  margin-right: 0.2rem;
  width: 2.4rem;
  background-color: #eee;
}
.articles-component .article-wrapper .article-lists .article-item .right {
  flex: 1 1;
  padding: 0.1rem 0;
}
.articles-component .article-wrapper .article-lists .article-item .right .title {
  margin-bottom: 0.12rem;
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
  color: #041133;
}
.articles-component .article-wrapper .article-lists .article-item .right .summary {
  height: 0.44rem;
  margin-bottom: 0.36rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(4, 17, 51, 0.7);
}
.articles-component .article-wrapper .article-lists .article-item .right .infos {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
}
.articles-component .article-wrapper .article-lists .article-item .right .infos .iconfont {
  margin-right: 0.04rem;
  font-size: 0.14rem;
}
.articles-component .article-wrapper .article-lists .article-item .right .infos .read-num {
  margin-left: 0.2rem;
}
.articles-component .article-wrapper .article-lists .article-item:hover {
  background: rgba(4, 17, 51, 0.05);
  border-radius: 0.1rem;
}
