.about-us-module2 .content {
  background: linear-gradient(292deg, rgba(35, 82, 216, 0.68) -18%, #2352d8 46%, #2352d8 61%, #2250d4 99%);
  margin-top: 0.4rem;
}
.about-us-module2 .content-md {
  padding-bottom: 0.52rem;
  display: flex;
  align-items: flex-start;
  position: relative;
}
.about-us-module2 .content-md::before {
  z-index: 0;
  position: absolute;
  left: -0.44rem;
  top: -2.4291rem;
  content: "";
  display: block;
  width: 4.3782rem;
  height: 10.364rem;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 83%);
}
.about-us-module2 .image-list {
  width: 5.4rem;
  position: relative;
}
.about-us-module2 .right {
  color: #ffffff;
  padding-left: 0.6rem;
  flex: 1 1;
}
.about-us-module2 .right-title {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.about-us-module2 .right-title .tag {
  width: 0.68rem;
  margin-right: 0.12rem;
}
.about-us-module2 .right-title .name {
  font-size: 0.32rem;
  font-weight: 600;
  line-height: 0.45rem;
}
.about-us-module2 .right-text {
  font-size: 0.14rem;
  line-height: 0.28rem;
  white-space: pre-line;
  padding-bottom: 0.32rem;
}
.about-us-module2 .right-subtitle {
  font-size: 0.24rem;
  font-weight: 600;
  line-height: 0.34rem;
  padding-bottom: 0.24rem;
}
.about-us-module2 .right-tags .tag {
  width: 1.1rem;
  height: 0.48rem;
  border-radius: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0.2rem;
  font-family: zihun2hao-liliangcuheiti-Regular;
}
