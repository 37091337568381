.menu-mobile-fixed {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  -webkit-backdrop-filter: blur(0.64rem);
  backdrop-filter: blur(0.64rem);
  z-index: 1004;
}
.menu-mobile-fixed .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.52rem;
  padding: 0 0.24rem;
}
.menu-mobile-fixed .header .logo {
  width: 0.8rem;
  height: 0.2rem;
}
.menu-mobile-fixed .header .icon-close {
  color: #fff;
}
.menu-mobile-fixed .route {
  display: flex;
  align-items: center;
  height: 0.52rem;
  margin: 0 0.24rem;
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.24rem;
}
.menu-mobile-fixed .route-theme {
  margin: 0 0.24rem 0 0.24rem;
  border-bottom: none;
  color: rgba(255, 255, 255, 0.8);
  font-weight: normal;
}
