.fr-science-pc.phone .fr-science-pc-banner {
  padding-top: 0.48rem;
  background-size: auto 3.06rem;
}
.fr-science-pc.phone .fr-science-pc-banner .bg {
  height: 70.22516556%;
}
.fr-science-pc.phone .fr-science-pc-banner .content {
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.4rem;
}
.fr-science-pc.phone .fr-science-pc-banner .content img {
  width: 90%;
  margin: 0 auto;
}
.fr-science-pc.phone .fr-science-pc-module1 .content,
.fr-science-pc.phone .fr-science-pc-module2 .content,
.fr-science-pc.phone .fr-science-pc-module3 .content {
  padding: 0.24rem 0;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .num,
.fr-science-pc.phone .fr-science-pc-module2 .content .num,
.fr-science-pc.phone .fr-science-pc-module3 .content .num {
  height: 0.24rem;
  margin-left: 0.24rem;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .title,
.fr-science-pc.phone .fr-science-pc-module2 .content .title,
.fr-science-pc.phone .fr-science-pc-module3 .content .title {
  font-size: 0.18rem;
  white-space: normal;
  line-height: 0.32rem;
  margin-bottom: 0.16rem;
  padding-left: 0.24rem;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .bot,
.fr-science-pc.phone .fr-science-pc-module2 .content .bot,
.fr-science-pc.phone .fr-science-pc-module3 .content .bot {
  display: flex;
  flex-direction: column;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .bot-item,
.fr-science-pc.phone .fr-science-pc-module2 .content .bot-item,
.fr-science-pc.phone .fr-science-pc-module3 .content .bot-item {
  width: 100%;
  height: auto;
  border-radius: 0;
  padding: 0.24rem;
  margin: 0;
  margin-top: 0.16rem;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .bot-item .pic,
.fr-science-pc.phone .fr-science-pc-module2 .content .bot-item .pic,
.fr-science-pc.phone .fr-science-pc-module3 .content .bot-item .pic {
  width: 100%;
}
.fr-science-pc.phone .fr-science-pc-module1 .content .bot-item:first-child,
.fr-science-pc.phone .fr-science-pc-module2 .content .bot-item:first-child,
.fr-science-pc.phone .fr-science-pc-module3 .content .bot-item:first-child {
  margin-top: 0;
}
.fr-science-pc.phone .fr-science-pc-module2 {
  padding-bottom: 0.4rem;
}
