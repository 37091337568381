.ql-editor .detail-text {
  padding: 0.3rem 0 0.4rem;
  color: #1c2947;
}
.ql-editor .detail-text .ql-align-center {
  text-align: center;
}
.ql-editor .detail-text p {
  font-size: 0.14rem;
  line-height: 0.24rem;
  color: #1c2947;
  margin-bottom: 0.34rem;
}
.ql-editor .detail-text p strong,
.ql-editor .detail-text p span,
.ql-editor .detail-text p a,
.ql-editor .detail-text p em,
.ql-editor .detail-text p b {
  font-size: 0.14rem;
}
.ql-editor .detail-text p strong,
.ql-editor .detail-text p span,
.ql-editor .detail-text p em,
.ql-editor .detail-text p b {
  font-size: 0.14rem;
  color: #1c2947 !important;
}
.ql-editor .detail-text p a {
  color: #3370ff !important;
  font-size: 0.14rem;
}
.ql-editor .detail-text p > img {
  max-width: 6.4rem;
}
.ql-editor .detail-text p span > img {
  max-width: 6.4rem;
  margin: 0 auto;
  display: block;
  margin-top: 0.15rem;
  margin-bottom: 0.48rem;
  height: auto !important;
}
.ql-editor .detail-text > p > img,
.ql-editor .detail-text > img {
  max-width: 6.4rem;
  height: auto !important;
  margin: 0 auto;
  display: block;
  margin-top: 0.15rem;
  margin-bottom: 0.48rem;
}
.ql-editor .detail-text h1,
.ql-editor .detail-text h2,
.ql-editor .detail-text h3,
.ql-editor .detail-text h4,
.ql-editor .detail-text h5,
.ql-editor .detail-text h6 {
  margin-bottom: 0.34rem;
  color: #1c2947;
}
.ql-editor .detail-text h1 strong,
.ql-editor .detail-text h2 strong,
.ql-editor .detail-text h3 strong,
.ql-editor .detail-text h4 strong,
.ql-editor .detail-text h5 strong,
.ql-editor .detail-text h6 strong,
.ql-editor .detail-text h1 span,
.ql-editor .detail-text h2 span,
.ql-editor .detail-text h3 span,
.ql-editor .detail-text h4 span,
.ql-editor .detail-text h5 span,
.ql-editor .detail-text h6 span,
.ql-editor .detail-text h1 em,
.ql-editor .detail-text h2 em,
.ql-editor .detail-text h3 em,
.ql-editor .detail-text h4 em,
.ql-editor .detail-text h5 em,
.ql-editor .detail-text h6 em,
.ql-editor .detail-text h1 b,
.ql-editor .detail-text h2 b,
.ql-editor .detail-text h3 b,
.ql-editor .detail-text h4 b,
.ql-editor .detail-text h5 b,
.ql-editor .detail-text h6 b {
  color: #1c2947 !important;
}
.ql-editor .detail-text h1 {
  font-size: 0.26rem;
}
.ql-editor .detail-text h2 {
  font-size: 0.19rem;
}
.ql-editor .detail-text h3 {
  font-size: 0.15rem;
}
.ql-editor .detail-text h4 {
  font-size: 0.13rem;
}
.ql-editor .detail-text h1 {
  font-size: 0.26rem;
}
.ql-editor .detail-text h2 {
  font-size: 0.19rem;
}
.ql-editor .detail-text h3 {
  font-size: 0.15rem;
}
.ql-editor .detail-text h4 {
  font-size: 0.13rem;
}
.ql-editor .detail-text ul,
.ql-editor .detail-text ol {
  font-size: 0.14rem;
  line-height: 0.24rem;
  color: #1c2947;
  margin-bottom: 0.34rem;
}
.ql-editor .detail-text ul li,
.ql-editor .detail-text ol li {
  color: #1c2947;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
}
.ql-editor .detail-text ul li strong,
.ql-editor .detail-text ol li strong,
.ql-editor .detail-text ul li span,
.ql-editor .detail-text ol li span,
.ql-editor .detail-text ul li a,
.ql-editor .detail-text ol li a,
.ql-editor .detail-text ul li em,
.ql-editor .detail-text ol li em,
.ql-editor .detail-text ul li b,
.ql-editor .detail-text ol li b {
  font-size: 0.14rem;
}
.ql-editor .detail-text ul li strong,
.ql-editor .detail-text ol li strong,
.ql-editor .detail-text ul li span,
.ql-editor .detail-text ol li span,
.ql-editor .detail-text ul li em,
.ql-editor .detail-text ol li em,
.ql-editor .detail-text ul li b,
.ql-editor .detail-text ol li b {
  color: #1c2947 !important;
}
.ql-editor .detail-text ul li::before,
.ql-editor .detail-text ol li::before {
  font-family: Helvetica, Arial, sans-serif;
}
.ql-editor .detail-text .ql-video {
  display: none;
}
.ql-editor .detail-text .ql-video video {
  display: none;
}
.ql-editor .detail-text .ref-video {
  display: block;
  width: 100%;
  min-height: 3.2rem;
  margin: 0.15rem auto 0.48rem;
  height: auto !important;
}
.ql-editor .detail-text .ref-video video {
  width: 100%;
  height: 100%;
}
.news-detail-mobile {
  padding-top: 0.48rem;
}
.news-detail-mobile .header-bg {
  display: none;
}
.news-detail-mobile .detail-content .bread-crumb {
  display: none;
}
.news-detail-mobile .detail-content .detail-module .detail-left {
  width: 100%;
  padding: 0 0.24rem;
}
.news-detail-mobile .detail-content .detail-module .detail-left .title {
  padding: 0.24rem 0 0;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.4rem;
  color: #041133;
}
.news-detail-mobile .detail-content .detail-module .detail-left .infos {
  display: flex;
  padding: 0.12rem 0 0.24rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail-mobile .detail-content .detail-module .detail-left .infos .date,
.news-detail-mobile .detail-content .detail-module .detail-left .infos .read-num {
  display: flex;
  align-items: center;
  font-size: 0.1rem;
}
.news-detail-mobile .detail-content .detail-module .detail-left .infos .iconfont {
  font-size: 0.14rem;
  margin-right: 0.04rem;
}
.news-detail-mobile .detail-content .detail-module .detail-left .infos .date {
  margin-right: 0.2rem;
}
.news-detail-mobile .detail-content .detail-module .detail-left .summary {
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.24rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid rgba(28, 41, 71, 0.1);
  font-size: 0.12rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom .line {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  width: 1px;
  height: 12px;
  background: rgba(4, 17, 51, 0.1);
}
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom .pre-item,
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom .next-item {
  max-width: 1.94rem;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  font-size: 0.12rem;
  line-height: 0.22rem;
  cursor: pointer;
}
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom .pre-item span,
.news-detail-mobile .detail-content .detail-module .detail-left .detail-bottom .next-item span {
  font-size: 0.12rem;
  color: #041133;
}
.news-detail-mobile .detail-content .detail-module .detail-right {
  padding: 0.24rem;
  background: #fff;
}
.news-detail-mobile .detail-content .detail-module .detail-right h2 {
  font-size: 0.16rem;
  font-weight: 600;
  color: #1c2947;
  margin-bottom: 0.48rem;
}
.news-detail-mobile .detail-content .detail-module .detail-right ul li {
  margin-bottom: 0.48rem;
}
.news-detail-mobile .detail-content .detail-module .detail-right ul li img {
  width: 100%;
}
.news-detail-mobile .detail-content .detail-module .detail-right ul li h4 {
  font-size: 0.16rem;
  font-weight: 500;
  color: #1c2947;
  margin-top: 0.08rem;
  margin-bottom: 0.08rem;
}
.news-detail-mobile .detail-content .detail-module .detail-right ul li span {
  font-size: 0.12rem;
  font-weight: normal;
  color: rgba(28, 41, 71, 0.65);
}
