.ground-page-mobile {
  overflow-x: hidden;
  overflow-y: auto;
  /* 头部header */
  /* 顶部banner */
  /* ForeSight | 产品参谋 */
  /* 面向企业的创新业务场景解决方案 */
  /* 添加微信群 */
  /* 底部banner */
}
.ground-page-mobile .ground-header {
  height: 0.56rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.24rem;
}
.ground-page-mobile .ground-header .ground-logo {
  width: 0.96rem;
  height: 0.24rem;
}
.ground-page-mobile .ground-header .ground-contact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ground-page-mobile .ground-header .ground-contact img {
  width: 1.29rem;
  height: 0.24rem;
  -webkit-animation: scaleDraw 5s ease-in-out infinite;
  animation: scaleDraw 5s ease-in-out infinite;
}
@-webkit-keyframes scaleDraw {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes scaleDraw {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.ground-page-mobile .ground-banner {
  height: 5.96rem;
  background-image: url('./images/mobileBanner.png');
  background-size: 100% 100%;
}
.ground-page-mobile .ground-banner .ground-banner-content {
  height: 100%;
  padding: 0 0.245rem;
  padding-top: 0.48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.ground-page-mobile .ground-banner .ground-banner-content .ground-title-children {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.45);
}
.ground-page-mobile .ground-banner .ground-banner-content .ground-title-children span {
  color: #2352d8;
}
.ground-page-mobile .ground-banner .ground-banner-content .ground-title {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.28rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #041133;
  margin-top: 0.08rem;
  margin-bottom: 0.08rem;
}
.ground-page-mobile .ground-banner .ground-banner-content .ground-title span {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.28rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #041133;
}
.ground-page-mobile .ground-banner .ground-banner-content p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.12rem;
  font-weight: normal;
  line-height: 0.2rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.65);
}
.ground-page-mobile .fore-sight {
  padding-top: 0.48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page-mobile .fore-sight .fore-sight-title {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.48rem;
  letter-spacing: 0em;
  color: #041133;
  padding: 0 0.24rem;
  margin-bottom: 0.24rem;
}
.ground-page-mobile .fore-sight .fore-sight-top {
  padding: 0 0.24rem;
  padding-bottom: 0.48rem;
}
.ground-page-mobile .fore-sight .fore-sight-top img {
  width: 100%;
  height: 7.24rem;
}
.ground-page-mobile .fore-sight .fore-sight-top p {
  margin-top: 0.24rem;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.65);
}
.ground-page-mobile .fore-sight .fore-sight-bottom {
  background: #EEF1F7;
  padding-top: 0.24rem;
}
.ground-page-mobile .fore-sight .fore-sight-bottom img:nth-of-type(1) {
  width: 100%;
  height: 2.16rem;
}
.ground-page-mobile .fore-sight .fore-sight-bottom img:nth-of-type(2) {
  width: 100%;
  height: 3.2rem;
}
.ground-page-mobile .ground-solution {
  margin-top: 0.14rem;
  background-image: url('./images/bgSolutionMobile.png');
  background-size: 100% 100%;
  padding-top: 0.96rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page-mobile .ground-solution .ground-solution-header {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 500;
  letter-spacing: 0em;
  color: #041133;
  text-align: center;
  margin-bottom: 0.48rem;
}
.ground-page-mobile .ground-solution .ground-solution-content {
  display: flex;
  flex-direction: column;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style {
  flex: 1 1;
  padding-bottom: 0.48rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style img {
  width: 100%;
  height: 3.2rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style div {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.18rem;
  font-weight: 600;
  line-height: 0.26rem;
  letter-spacing: 0em;
  color: #2352D8;
  margin-top: 0.24rem;
  margin-bottom: 0.08rem;
  padding: 0 0.24rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style div span {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.18rem;
  font-weight: 600;
  line-height: 0.26rem;
  letter-spacing: 0em;
  color: #2352D8;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style div i {
  display: inline-block;
  width: 0.3rem;
  height: 0.12rem;
  background-image: url('./images/playIconMobile.png');
  background-size: 100% 100%;
  margin-left: 0.08rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style p {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #041133;
  padding: 0 0.24rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style .solution-text-mobile {
  display: block;
  padding: 0 0.24rem;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.65);
  margin-top: 0.24rem;
}
.ground-page-mobile .ground-solution .ground-solution-content .solution-all-style .contact-btn {
  display: none;
}
.ground-page-mobile .ground-solution-advertisement {
  height: 5.26rem;
  background-image: url('./images/frontisBgMobile.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0.48rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.48rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left div {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 500;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.08rem;
  text-align: center;
  line-height: 0.48rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left div span {
  display: block;
  font-family: SourceHanSansSC-Medium;
  font-size: 0.32rem;
  font-weight: 500;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.08rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.26rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left span {
  display: block;
  padding: 0 0.24rem;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.12rem;
  font-weight: normal;
  line-height: 0.2rem;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right img {
  width: 1.76rem;
  height: 1.76rem;
}
.ground-page-mobile .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right p {
  width: 1.76rem;
  text-align: center;
  font-family: SourceHanSansSC-Medium;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-top: 0.08rem;
}
.ground-page-mobile .ground-footer {
  height: 8.63rem;
  background-image: url('./images/footerBgMobile.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page-mobile .ground-footer .ground-footer-content {
  height: 100%;
  position: relative;
  padding-top: 0.5527rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-text {
  display: flex;
  justify-content: center;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-text i {
  display: block;
  width: 0.34rem;
  height: 0.7rem;
  background-image: url('./images/free.png');
  background-size: 100% 100%;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-text div {
  margin-left: 0.08rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-text div p {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.32rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-text div span {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.24rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-list {
  display: flex;
  justify-content: space-between;
  margin-top: 0.48rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-list li {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-list li img {
  width: 0.32rem;
  height: 0.32rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-list li p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-top: 0.07rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-play {
  height: 0.76rem;
  padding: 0 0.16rem;
  margin-top: 0.64rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading {
  display: flex;
  align-items: center;
  padding: 0.16rem 0.24rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-right: 0.16rem;
}
.ground-page-mobile .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading img {
  width: 0.25rem;
  height: 0.13rem;
}
