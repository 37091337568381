.fr-product-marketing-pc.phone .fr-product-marketing-banner .common-banner-box {
  padding-top: 0.69rem;
}
.fr-product-marketing-pc.phone .marketing-module1 .module1-pic {
  width: calc(100% - 0.4rem);
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content {
  height: auto;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item {
  position: static;
  position: initial;
  display: none;
  flex-direction: column;
  padding: 0.2rem;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item.show {
  display: flex;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item.show .right-box {
  opacity: 1;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box {
  padding-right: 0;
  padding-bottom: 0.2rem;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .tab-content-title {
  font-size: 0.18rem;
  line-height: 0.22rem;
  margin-top: 0.2rem;
  margin-bottom: 0.08rem;
  text-align: center;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .tab-content-text {
  font-size: 0.12rem;
  line-height: 0.18rem;
  text-align: center;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .tag-box {
  margin-top: 0.12rem;
  justify-content: center;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .tag-box img {
  margin-right: 0.02rem;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .tag-box .tag {
  font-size: 0.12rem;
  height: 0.18rem;
  margin-right: 0.1rem;
  line-height: 0.18rem;
  margin-bottom: 0.3rem;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .left-box .common-primary-btn {
  margin: 0 auto;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .right-box {
  border-radius: 0.06rem;
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .right-box img,
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .right-box video {
  width: calc(100vw - 0.4rem);
  height: calc((100vw - 0.4rem) * (4 / 6));
}
.fr-product-marketing-pc.phone .marketing-module2 .tab-content .tab-content-item .right-box .tab-box {
  height: 0.35rem;
  padding: 0.045rem 0.1rem 0 0.1rem;
}
.fr-product-marketing-pc.phone .marketing-module3 .images {
  padding-left: 0;
  padding-right: 0;
}
.fr-product-marketing-pc.phone .marketing-module3 .images img {
  width: 1rem;
}
