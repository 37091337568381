.common-banner.join-us-module3 {
  background-size: cover;
}
.common-banner.join-us-module3 .common-banner-main {
  height: 3.26rem;
}
.common-banner.join-us-module3 .common-banner-box {
  padding-top: 0.97rem;
}
.common-banner.join-us-module3 .common-banner-title,
.common-banner.join-us-module3 .common-banner-text {
  color: #fff;
}
.common-banner.join-us-module3 .common-banner-title {
  white-space: normal;
}
.common-banner.join-us-module3 .common-banner-text {
  font-size: 0.16rem;
  line-height: 0.23rem;
  font-weight: bold;
}
