.home-module4 .module4-content {
  background: #12141a;
  margin-top: 0.4rem;
  display: flex;
  color: #ffffff;
}
.home-module4 .module4-left {
  padding-top: 0.8rem;
  padding-left: 0.48rem;
  width: 4.8rem;
}
.home-module4 .module4-left .left-title {
  font-size: 0.24rem;
  margin-bottom: 0.08rem;
}
.home-module4 .module4-left .left-big {
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
}
.home-module4 .module4-left .left-line {
  width: 0.32rem;
  height: 0.02rem;
  background-color: #fff;
  margin: 0.8rem 0;
}
.home-module4 .module4-left .left-text {
  white-space: pre-line;
  line-height: 0.24rem;
  font-size: 0.16rem;
}
.home-module4 .module4-list {
  display: flex;
  flex: 1 1;
}
.home-module4 .module4-list .module4-col {
  flex: 1 1;
  position: relative;
}
.home-module4 .module4-list .module4-col:hover .col-main {
  background-color: rgba(28, 41, 71, 0.9);
}
.home-module4 .module4-list .module4-col:hover .hover-box {
  display: flex;
}
.home-module4 .module4-list .module4-col:hover .col-title {
  text-align: left;
}
.home-module4 .module4-list .col-pic {
  width: 100%;
  display: block;
}
.home-module4 .module4-list .col-main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.48rem 0.3rem;
  flex-direction: column;
}
.home-module4 .module4-list .col-title {
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  width: 100%;
  text-align: right;
}
.home-module4 .module4-list .hover-box {
  flex: 1 1;
  flex-direction: column;
  display: none;
}
.home-module4 .module4-list .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  transition: all 0.2s;
  height: 0.64rem;
  width: 0.64rem;
  margin-bottom: auto;
  position: relative;
}
.home-module4 .module4-list .arrow:not(.disabled):hover {
  background: #2352d8;
  height: 0.8rem;
  width: 0.8rem;
}
.home-module4 .module4-list .arrow.disabled {
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.05);
}
.home-module4 .module4-list .arrow.disabled img {
  opacity: 0.3;
}
.home-module4 .module4-list .arrow.disabled:hover .coming-soon-tips {
  display: block;
  bottom: 100%;
}
.home-module4 .module4-list .hover-line {
  background-color: #d8d8d8;
  height: 0.01rem;
  margin: 0.48rem 0;
  width: 0.25rem;
}
.home-module4 .module4-list .hover-text {
  color: rgba(255, 255, 255, 0.65);
  font-size: 0.14rem;
  line-height: 0.22rem;
  margin-top: auto;
}
