.marketing-module3 {
  background: #f7f8fa;
}
.marketing-module3 .images {
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.marketing-module3 .images img {
  width: 1.8rem;
}
