.join-us-banner.common-banner {
  background-size: cover;
}
.join-us-banner.common-banner .common-banner-main {
  height: 2.4rem;
  text-align: center;
  line-height: 2.4rem;
  font-size: 0.7rem;
  color: #ffffff;
  font-family: zihun2hao-liliangcuheiti-Regular;
}
