.maas-module1 .item {
  border-radius: 0.1rem;
  padding: 0.3rem;
  background: linear-gradient(0deg, rgba(238, 241, 247, 0.4) 0%, rgba(238, 241, 247, 0) 100%);
}
.maas-module1 .item .item-icon {
  margin-bottom: 0.3rem;
  display: block;
}
.maas-module1 .item .item-text {
  color: rgba(4, 17, 51, 0.65);
}
