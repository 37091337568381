.fr-small-tabs {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 0.95rem;
}
.fr-small-tabs::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.02rem;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}
.fr-small-tabs .tabs-item {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.5rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.fr-small-tabs .tabs-item.active {
  font-weight: 500;
  color: #ffffff;
}
.fr-small-tabs .line-box {
  position: absolute;
  left: 0.95rem;
  right: 0.95rem;
  bottom: 0;
}
.fr-small-tabs .line {
  height: 0.01rem;
  background: #ffffff;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.2s;
}
