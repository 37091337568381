.fr-service-window-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 554;
  display: none;
}
.fr-service-window {
  box-sizing: border-box;
  position: fixed;
  bottom: 0.18rem;
  right: 0.65rem;
  width: 5.1rem;
  z-index: 1003;
  padding-right: 0.15rem;
  display: none;
}
.fr-service-window .fr-service-window-content {
  width: 5rem;
  border-radius: 0.1rem;
  padding: 0.3rem;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0 rgba(4, 17, 51, 0.16);
  max-height: 6rem;
  overflow: hidden;
  overflow-y: auto;
}
.fr-service-window .service-window-title {
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #1c2947;
  margin-bottom: 0.04rem;
}
.fr-service-window .service-window-text {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
  margin-bottom: 0.24rem;
}
.fr-service-window .alter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fr-service-window .alter-form li {
  width: 100%;
  height: 0.4rem;
  outline: none;
  display: flex;
  border-radius: 0.04rem;
}
.fr-service-window .alter-form li input {
  height: 0.4rem;
  line-height: 0.4rem;
}
.fr-service-window .alter-form li textarea {
  resize: none;
}
.fr-service-window .alter-form li input,
.fr-service-window .alter-form li textarea {
  border-radius: 0.04rem;
  flex: 1 1;
  border: 0.01rem solid rgba(4, 17, 51, 0.1);
  background-color: #fff;
  outline: none;
  padding: 0.08rem 0.16rem;
  font-size: 0.14rem;
}
.fr-service-window .alter-form li input::-moz-placeholder,
.fr-service-window .alter-form li textarea::-moz-placeholder {
  font-size: 0.14rem;
  color: rgba(28, 41, 71, 0.45);
  opacity: 1;
}
.fr-service-window .alter-form li input::-ms-input-placeholder,
.fr-service-window .alter-form li textarea::-ms-input-placeholder {
  font-size: 0.14rem;
  color: rgba(28, 41, 71, 0.45);
  opacity: 1;
}
.fr-service-window .alter-form li input::-webkit-input-placeholder,
.fr-service-window .alter-form li textarea::-webkit-input-placeholder {
  font-size: 0.14rem;
  color: rgba(28, 41, 71, 0.45);
  opacity: 1;
}
.fr-service-window .alter-form li input:focus,
.fr-service-window .alter-form li textarea:focus {
  border-color: #2352d8;
}
.fr-service-window .alter-form li .textarea-box {
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
}
.fr-service-window .alter-form li .textarea-box textarea {
  border: 0;
  width: 100%;
  height: 0.66rem;
}
.fr-service-window .alter-form li .textarea-box textarea:focus + .border-box {
  border-color: #2352d8;
}
.fr-service-window .alter-form li .textarea-box .border-box {
  border: 0.01rem solid rgba(4, 17, 51, 0.1);
  border-radius: 0.04rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.fr-service-window .alter-form li .textarea-box span {
  color: rgba(28, 41, 71, 0.45);
  padding: 0 0.16rem 0.16rem 0.16rem;
  line-height: 0.22rem;
}
.fr-service-window .alter-form li p {
  font-size: 0.16rem;
  font-weight: normal;
  letter-spacing: 0em;
  cursor: pointer;
}
.fr-service-window .alter-form .contact-textarea {
  height: 1.04rem;
}
.fr-service-window .alter-form .i-text {
  width: 100%;
  height: 0.16rem;
  color: red;
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
}
.fr-service-window .alter-form .i-text span {
  font-size: 0.1rem;
  line-height: 0.14rem;
}
.fr-service-window .phone-box {
  display: flex;
  flex-wrap: wrap;
}
.fr-service-window .phone-box li {
  position: relative;
  width: 2.24rem;
}
.fr-service-window .phone-box li:first-child {
  margin-right: 0.16rem;
}
.fr-service-window .phone-box li.code-box input {
  flex: 0 1;
  width: 1.12rem;
}
.fr-service-window .phone-box li.code-box span {
  position: absolute;
  right: 0.04rem;
  width: 0.9rem;
  font-size: 0.14rem;
  color: #2352d8;
}
.fr-service-window .agreement-box {
  margin-top: 0.12rem;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
}
.fr-service-window .agreement-box .read-span {
  color: #2352d8;
  font-size: 0.14rem;
  cursor: pointer;
}
.fr-service-window .agreement-box .my-checkbox {
  width: 0.2rem;
  height: 0.22rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.fr-service-window .agreement-box .my-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.fr-service-window .agreement-box .my-checkbox .my-checkbox-inner {
  padding-left: 0.01rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.fr-service-window .agreement-box .my-checkbox .my-checkbox-inner img {
  width: 0.14rem;
  height: 0.14rem;
}
.fr-service-window .close-icon {
  display: none;
}
.fr-service-window .submit-btn {
  margin-top: 0.32rem;
  width: 100%;
}
.fr-service-window .submit-btn button {
  width: 100%;
  height: 0.48rem;
  border-radius: 0.04rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2352d8;
  color: #fff;
  font-size: 0.16rem;
  border: none;
  outline: none;
  cursor: pointer;
}
.fr-service-window .submit-btn button.disabled {
  background-color: rgba(35, 82, 216, 0.4);
  cursor: not-allowed;
}
.fr-service-window .fr-service-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.32rem;
}
.fr-service-window .fr-service-customer .bot-title {
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
  margin-bottom: 0.08rem;
}
.fr-service-window .fr-service-customer .bot-qrcode {
  width: 0.8rem;
  margin-bottom: 0.08rem;
}
.fr-service-window .fr-service-customer .mobile {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
}
.fr-service-window .fr-service-customer .mobile .contract-icon {
  width: 0.16rem;
  margin-right: 0.1rem;
}
