.common-banner.home-pc-banner {
  background-position: bottom right;
}
.common-banner.home-pc-banner .common-banner-main {
  height: 5rem;
}
.common-banner.home-pc-banner .common-banner-box {
  padding-top: 1.71rem;
}
@media screen and (min-width: 14.4rem) {
  .common-banner.home-pc-banner {
    background-size: cover;
    background-position: center right;
  }
}
