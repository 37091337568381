.articles-component.articles-component-mobile .article-tabs {
  padding: 0.16rem 0 0.24rem;
  justify-content: space-around;
}
.articles-component.articles-component-mobile .article-tabs .article-tab {
  margin-right: 0.12rem;
  padding-bottom: 0.08rem;
  font-size: 0.12rem;
  line-height: 0.22rem;
  color: #041133;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists {
  padding: 0 0.16rem;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item {
  padding: 0;
  margin-bottom: 0.24rem;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .cover {
  margin-right: 0.1rem;
  width: 1rem;
  height: 0.66rem;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right {
  padding: 0;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right .title {
  margin-bottom: 0.05rem;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.22rem;
  height: 0.44rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #041133;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right .summary {
  font-size: 0.12rem;
  line-height: 0.2rem;
  height: 0.2rem;
  -webkit-line-clamp: 1;
  color: rgba(4, 17, 51, 0.7);
  margin-bottom: 0.08rem;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right .infos {
  justify-content: end;
}
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right .infos .date,
.articles-component.articles-component-mobile .article-wrapper .article-lists .article-item .right .infos .read-num {
  font-size: 0.12rem;
  display: flex;
  min-width: 0.32rem;
}
.articles-component.articles-component-mobile .article-wrapper .article-empty {
  font-size: 0.14rem;
}
