.fr-science-pc-module2 {
  width: 100%;
  padding-bottom: 0.8rem;
}
.fr-science-pc-module2 .content {
  max-width: 12rem;
  margin: 0 auto;
  padding-top: 0.8rem;
}
.fr-science-pc-module2 .content .num {
  display: block;
  height: 0.61rem;
}
.fr-science-pc-module2 .content .title {
  font-size: 0.34rem;
  font-weight: 600;
  line-height: 0.48rem;
  color: #041133;
  margin-bottom: 0.4rem;
}
.fr-science-pc-module2 .content .bot {
  display: flex;
}
.fr-science-pc-module2 .content .bot-item {
  width: 3.8rem;
  height: 4.94rem;
  border-radius: 0.1rem;
  background: rgba(35, 82, 216, 0.05);
  margin-left: 0.3rem;
  padding: 0.3rem;
}
.fr-science-pc-module2 .content .bot-item:first-child {
  margin-left: 0;
}
.fr-science-pc-module2 .content .bot-item .pic {
  width: 3.2rem;
  border-radius: 0.1rem;
  margin: 0 auto;
  margin-bottom: 0.3rem;
}
.fr-science-pc-module2 .content .bot-item .bot-title {
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #041133;
  margin-bottom: 0.04rem;
}
.fr-science-pc-module2 .content .bot-item .bot-text {
  display: inline-block;
  height: 0.26rem;
  border-radius: 0.04rem;
  padding: 0 0.08rem;
  background: rgba(35, 82, 216, 0.05);
  font-size: 0.14rem;
  line-height: 0.26rem;
  color: #2352d8;
  margin-bottom: 0.24rem;
}
.fr-science-pc-module2 .content .bot-item li {
  margin-bottom: 0.08rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
  display: flex;
  align-items: flex-start;
}
.fr-science-pc-module2 .content .bot-item li span {
  font-size: 0.14rem;
}
.fr-science-pc-module2 .content .bot-item li span:first-child {
  width: 14px;
  text-align: center;
  margin-right: 4px;
  flex-shrink: 0;
}
