.home-pc-warp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFang SC;
}
.home-pc-warp .home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-pc-warp .w1200 {
  width: 100%;
  max-width: 12rem;
}
