.contact-us-mobile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(28, 41, 71, 0.8);
  z-index: 1004;
}
.contact-us-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  z-index: 1000;
  border-radius: 0.2rem 0.2rem 0 0;
}
.contact-us-mobile .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.54rem;
  padding: 0 0.24rem;
  border-bottom: 0.01rem solid rgba(4, 17, 51, 0.07);
}
.contact-us-mobile .item div {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
}
.contact-us-mobile .item .right-text {
  font-weight: 500;
  color: #041133;
  border-bottom: none;
}
.contact-us-mobile .item .right-text.flex {
  display: flex;
  align-items: center;
}
.contact-us-mobile .item .right-text .copy {
  width: 0.14rem;
  height: 0.14rem;
  margin-left: 0.06rem;
}
.contact-us-mobile .header {
  height: 0.62rem;
  border-bottom: none;
}
.contact-us-mobile .header .header-title {
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
  color: #041133;
}
.contact-us-mobile .header .icon-close {
  font-size: 0.18rem;
  color: rgba(4, 17, 51, 0.65);
}
.contact-us-mobile .phone .clip {
  color: #2352d8;
}
.contact-us-mobile .code {
  padding: 0.16rem 0.24rem;
  align-items: flex-start;
  height: auto;
  font-size: 0.14rem;
}
.contact-us-mobile .code .right-img {
  width: 0.8rem;
  height: 0.8rem;
}
.contact-us-mobile .contact {
  padding: 0.3rem 0.24rem;
  height: auto;
}
