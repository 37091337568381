.join-us-module2 .common-module-text {
  color: #1c2947;
  margin-bottom: 0.08rem;
}
.join-us-module2 .benefit-list {
  display: flex;
  padding: 0.48rem 0.08rem 0.9rem 0.08rem;
  justify-content: space-between;
}
.join-us-module2 .benefit-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.join-us-module2 .benefit-list li img {
  display: block;
  height: 0.48rem;
  margin-bottom: 0.08rem;
}
.join-us-module2 .benefit-list li p {
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.22rem;
}
