.banner-resource {
  position: relative;
  width: 100%;
  padding-top: 16.7%;
}
.banner-resource .resource-banner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
