.marketing-module2 {
  position: relative;
}
.marketing-module2 .tab-content {
  width: 100%;
  height: 4.8rem;
  position: relative;
}
.marketing-module2 .tab-content .tab-content-item {
  padding: 0.4rem 0.2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.marketing-module2 .tab-content .tab-content-item.show {
  display: flex;
  opacity: 1;
  z-index: 1;
  pointer-events: all;
}
.marketing-module2 .tab-content .tab-content-item.show .right-box {
  opacity: 1;
}
.marketing-module2 .tab-content .tab-content-item .left-box {
  flex: 1 1;
  padding-right: 0.5rem;
}
.marketing-module2 .tab-content .tab-content-item .left-box .tab-content-title {
  font-size: 0.28rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #151618;
  margin-top: 0.8rem;
  margin-bottom: 0.16rem;
}
.marketing-module2 .tab-content .tab-content-item .left-box .tab-content-text {
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #8a8d94;
}
.marketing-module2 .tab-content .tab-content-item .left-box .tag-box {
  display: flex;
  margin-top: 0.24rem;
}
.marketing-module2 .tab-content .tab-content-item .left-box .tag-box img {
  margin-right: 0.04rem;
}
.marketing-module2 .tab-content .tab-content-item .left-box .tag-box .tag {
  font-size: 0.16rem;
  height: 0.24rem;
  display: flex;
  align-items: center;
  margin-right: 0.2rem;
  color: #323438;
  line-height: 0.24rem;
  margin-bottom: 0.6rem;
}
.marketing-module2 .tab-content .tab-content-item .left-box .common-primary-btn {
  width: 1.24rem;
}
.marketing-module2 .tab-content .tab-content-item .right-box {
  width: 6rem;
  border-radius: 0.12rem;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: all 0.5s;
}
.marketing-module2 .tab-content .tab-content-item .right-box img,
.marketing-module2 .tab-content .tab-content-item .right-box video {
  width: 6rem;
  height: 4rem;
  -webkit-animation: opa 0.5s;
  animation: opa 0.5s;
}
.marketing-module2 .tab-content .tab-content-item .right-box .tab-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.021) 7%, rgba(0, 0, 0, 0.7) 100%);
  border-radius: 0.12rem;
  height: 0.7rem;
  padding: 0.09rem 0.2rem 0 0.2rem;
}
@-webkit-keyframes opa {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opa {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
