.custom-select-element {
  position: relative;
  width: 100%;
  height: 0.4rem;
  z-index: 1;
}
.custom-select-element .select-selected {
  height: 0.4rem;
  line-height: 0.4rem;
  width: 100%;
  padding: 0 0.16rem;
  border: 0.01rem solid rgba(4, 17, 51, 0.1);
  border-radius: 0.04rem;
  font-size: 0.14rem;
  color: #041133;
  position: relative;
  pointer-events: none;
}
.custom-select-element .select-selected.placeholder {
  color: rgba(28, 41, 71, 0.45);
  font-weight: normal;
}
.custom-select-element .select-selected .arrow {
  position: absolute;
  width: 0.16rem;
  top: 0.12rem;
  right: 0.16rem;
  pointer-events: none;
}
.custom-select-element input {
  height: 0.4rem;
  line-height: 0.4rem;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.custom-select-element input:focus + .select-selected {
  border-color: #2352d8;
}
.custom-select-element .select-items {
  display: none;
  position: absolute;
  top: 0.44rem;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0 rgba(4, 17, 51, 0.16);
  border-radius: 0.08rem;
  padding: 0.04rem;
}
.custom-select-element .select-items.show {
  display: block;
}
.custom-select-element .select-items .select-item {
  height: 0.42rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: #041133;
  line-height: 0.42rem;
  padding: 0 0.12rem;
  cursor: pointer;
}
.custom-select-element .select-items .select-item:hover {
  background: rgba(35, 82, 216, 0.05);
}
.custom-select-element .select-items .select-item:active,
.custom-select-element .select-items .select-item.active {
  background: #2352d8;
  color: #fff;
}
