.innovate {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innovate .innovate-content {
  width: 12rem;
  height: auto;
  padding: 0 0.48rem;
}
.innovate .innovate-content h2 {
  margin-top: 0.8rem;
  font-size: 0.34rem;
  font-weight: 600;
  color: #041133;
  text-align: center;
}
.innovate .innovate-content p {
  margin-top: 0.12rem;
  font-size: 0.16rem;
  line-height: 0.24rem;
  text-align: center;
  font-weight: normal;
  color: #9E9E9E;
}
.innovate .innovate-content .innovate-img {
  display: block;
  width: 10.6rem;
  height: 5.05rem;
  margin: 0 auto;
}
.innovate .innovate-content .funtion-list {
  width: 100%;
  height: 2.91rem;
  margin-top: 0.83rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innovate .innovate-content .funtion-list ul {
  width: 100%;
  max-width: 13.44rem;
  display: flex;
}
.innovate .innovate-content .funtion-list ul li {
  margin-right: 0.24rem;
}
.innovate .innovate-content .funtion-list ul li:last-child {
  margin-right: 0;
}
.innovate .innovate-content .funtion-list ul li {
  width: 3.18rem;
  height: 2.32rem;
  background-color: #fff;
  position: relative;
  padding: 0.48rem 0.45rem 0.43rem 0.48rem;
}
.innovate .innovate-content .funtion-list ul li b {
  font-family: SourceHanSansCN-Bold;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #1C2947;
}
.innovate .innovate-content .funtion-list ul li img {
  width: 0.96rem;
  height: 0.96rem;
  position: absolute;
  right: 0.24rem;
  top: -0.32rem;
}
.innovate .innovate-content .funtion-list ul li p {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: rgba(28, 41, 71, 0.65);
  margin-top: 0.33rem;
}
