.common-banner.fr-product-maas-banner {
  background-color: #f6fcff;
}
.common-banner.fr-product-maas-banner .common-banner-main {
  height: 5rem;
}
.common-banner.fr-product-maas-banner .common-banner-box {
  padding-top: 1.38rem;
}
@media screen and (min-width: 14.4rem) {
  .common-banner.fr-product-maas-banner {
    background-size: cover;
  }
}
