.home-module3 {
  background: no-repeat center top;
  background-size: 100% auto;
}
.home-module3 .module3-card {
  padding: 0.3rem 0.24rem 0.24rem;
}
.home-module3 .module3-card .card-icon {
  width: 0.5rem;
  margin-bottom: 0.16rem;
}
