.submit-dom {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(0.2rem);
  backdrop-filter: blur(0.2rem);
  margin-top: 0 !important;
}
.submit-dom .submit-success {
  width: 2.45rem;
  height: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.24rem;
  border-radius: 0.08rem;
  background: rgba(0, 181, 120, 0.1);
  box-sizing: border-box;
  border: 0.01rem solid #00B578;
  margin-top: 0 !important;
}
.submit-dom .submit-success img {
  width: 0.48rem;
  height: 0.48rem;
}
.submit-dom .submit-success h2 {
  margin-top: 0.3rem;
  margin-bottom: 0.08rem;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.24rem;
  font-weight: normal;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #00B578;
}
.submit-dom .submit-success p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.12rem;
  font-weight: normal;
  line-height: 0.2rem;
  letter-spacing: 0em;
  color: #00B578;
}
.official-contact-header {
  width: 100vw;
  height: 0.64rem;
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  padding: 0 0.24rem;
}
.official-contact-header div {
  width: 1.27rem;
  height: 0.32rem;
  background-size: 100% 100%;
}
