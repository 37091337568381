.fr-science-pc-module1 {
  width: 100%;
}
.fr-science-pc-module1 .content {
  max-width: 12rem;
  margin: 0 auto;
  padding-top: 0.8rem;
}
.fr-science-pc-module1 .content .num {
  display: block;
  height: 0.61rem;
}
.fr-science-pc-module1 .content .title {
  font-size: 0.34rem;
  font-weight: 600;
  line-height: 0.48rem;
  color: #041133;
  margin-bottom: 0.4rem;
}
.fr-science-pc-module1 .content .bot {
  display: flex;
}
.fr-science-pc-module1 .content .bot-item {
  flex-shrink: 0;
  width: 6rem;
  height: 3.19rem;
  border-radius: 0.1rem;
  padding: 0.4rem 0.5rem 0.4rem 0.4rem;
  background: linear-gradient(270deg, rgba(238, 241, 247, 0) 0%, rgba(238, 241, 247, 0.5) 100%);
  margin-left: 0.2rem;
}
.fr-science-pc-module1 .content .bot-item:first-child {
  margin-left: 0;
}
.fr-science-pc-module1 .content .bot-item .bot-title {
  font-size: 0.24rem;
  font-weight: 600;
  color: #041133;
  line-height: 0.34rem;
  height: 0.34rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.25rem;
}
.fr-science-pc-module1 .content .bot-item .bot-title::before {
  content: "";
  width: 0.96rem;
  height: 0.08rem;
  background: linear-gradient(270deg, rgba(35, 82, 216, 0) 0%, #2352d8 100%);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.fr-science-pc-module1 .content .bot-item .bot-text {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
  margin-bottom: 0.1rem;
}
.fr-science-pc-module1 .content .bot-item li {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: flex-start;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
}
.fr-science-pc-module1 .content .bot-item li span {
  font-size: 0.14rem;
  line-height: 0.22rem;
}
.fr-science-pc-module1 .content .bot-item li .blue {
  color: #2352d8;
  display: block;
}
.fr-science-pc-module1 .content .bot-item li::before {
  content: "";
  width: 0.06rem;
  height: 0.06rem;
  background: #2352d8;
  border-radius: 100%;
  display: block;
  margin-right: 0.12rem;
  margin-top: 0.07rem;
  flex-shrink: 0;
}
