.submit-dom-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(0.2rem);
  backdrop-filter: blur(0.2rem);
  margin-top: 0 !important;
  z-index: 9999;
}
.submit-dom-mobile .submit-success {
  width: 2.45rem;
  height: 2.28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.24rem;
  border-radius: 0.08rem;
  background: rgba(4, 17, 51, 0.07);
  box-sizing: border-box;
  border: 0.01rem solid #041133;
  margin-top: 0 !important;
}
.submit-dom-mobile .submit-success img {
  width: 0.48rem;
  height: 0.48rem;
}
.submit-dom-mobile .submit-success h2 {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.24rem;
  font-weight: normal;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #041133;
  margin-top: 0.24rem;
  margin-bottom: 0.08rem;
}
.submit-dom-mobile .submit-success p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.12rem;
  font-weight: normal;
  line-height: 0.2rem;
  letter-spacing: 0em;
  color: #041133;
}
.submit-dom-mobile .submit-success div {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.16rem;
  font-weight: normal;
  line-height: 0.24rem;
  letter-spacing: 0em;
  text-decoration: underline;
  color: #2352D8;
  margin-top: 0.24rem;
}
.official-contact-mobile .alter-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 1.04rem 0.24rem 0 0.24rem;
  background-color: #fff;
}
.official-contact-mobile .alter-content dd {
  flex: 1 1;
}
.official-contact-mobile .alter-content dd h1 {
  text-align: left;
  height: 0.68rem;
  font-family: SourceHanSansCN-Bold;
  font-size: 0.18rem;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #1C2947;
  background-image: url('../contact/images/joinUsIcon.png');
  background-repeat: no-repeat;
  background-size: 0.89rem 0.58rem;
  background-position: bottom right;
  display: flex;
  font-weight: 600;
  align-items: center;
  margin: 0;
}
.official-contact-mobile .alter-content dd .alter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.official-contact-mobile .alter-content dd .alter-form li:last-of-type {
  height: 1.12rem;
}
.official-contact-mobile .alter-content dd .alter-form .get-codes input {
  width: 50%;
}
.official-contact-mobile .alter-content dd .alter-form .get-codes p {
  flex: 1 1;
  text-align: right;
}
.official-contact-mobile .alter-content dd .alter-form li {
  width: 100%;
  height: 0.4rem;
  border: 0.01rem solid rgba(28, 41, 71, 0.1);
  outline: none;
  display: flex;
  padding: 0.08rem 0.1rem;
  background: #EEF1F7;
}
.official-contact-mobile .alter-content dd .alter-form li input {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #EEF1F7;
  font-size: 0.14rem;
}
.official-contact-mobile .alter-content dd .alter-form li textarea {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #EEF1F7;
  resize: none;
  font-size: 0.14rem;
}
.official-contact-mobile .alter-content dd .alter-form li p {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  cursor: pointer;
}
.official-contact-mobile .alter-content dd .alter-form li span {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: rgba(28, 41, 71, 0.45);
}
.official-contact-mobile .alter-content dd .alter-form li:nth-of-type(even) {
  height: 0.16rem;
  font-size: 0.12rem;
  color: red;
  padding: 0;
  border: 0;
  background: none;
}
.official-contact-mobile .alter-content dd button {
  margin-top: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.4rem;
  border: 0;
  font-family: SourceHanSansCN-Bold;
  font-size: 0.16rem;
  font-weight: 500;
  color: #FFFFFF;
  background: #2352D8;
}
.official-contact-mobile .close {
  display: none;
}
