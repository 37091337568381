.fr-science-pc-banner {
  width: 100vw;
  position: relative;
  padding-top: 0.6rem;
  box-sizing: border-box;
  background-color: #eef1f7;
  background-position: center bottom;
  background-size: auto 3.06rem;
}
.fr-science-pc-banner .bg {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 66.22516556%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.fr-science-pc-banner .content {
  position: relative;
  width: 12rem;
  margin: 0 auto;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
}
.fr-science-pc-banner .content img {
  width: 100%;
  display: block;
}
