.mobile-service-window.fr-service-window {
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1004;
}
.mobile-service-window.fr-service-window .fr-service-window-content {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  padding: 0.24rem;
  border-radius: 0;
  border: 0;
}
.mobile-service-window.fr-service-window .close-icon {
  color: rgba(4, 17, 51, 0.65);
  font-size: 0.18rem;
  position: absolute;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  right: 0.04rem;
  top: 0.06rem;
  display: block;
}
.mobile-service-window.fr-service-window .agreement-box {
  flex-wrap: wrap;
}
.mobile-service-window.fr-service-window .alter-form input,
.mobile-service-window.fr-service-window .alter-form textarea,
.mobile-service-window.fr-service-window .alter-form .custom-select-element .select-selected,
.mobile-service-window.fr-service-window .alter-form .textarea-box .border-box {
  border-radius: 0.01rem;
}
.mobile-service-window.fr-service-window .alter-form .i-text {
  height: 0.16rem;
}
.mobile-service-window.fr-service-window .submit-btn {
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  box-shadow: 0 0 16px 0 rgba(4, 17, 51, 0.16);
  left: 0;
  padding: 0.12rem 0.2rem 0.3rem;
  position: absolute;
  width: 100vw;
}
.mobile-service-window.fr-service-window .submit-btn button {
  height: 0.4rem;
  border-radius: 0.01rem;
}
.mobile-service-window.fr-service-window .fr-service-customer {
  display: none;
}
