.alter-window-mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}
.alter-window-mobile .alter-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0.24rem;
  background-color: #fff;
}
.alter-window-mobile .alter-content dd {
  flex: 1 1;
  overflow-y: auto;
  padding: 0 0.02rem;
}
.alter-window-mobile .alter-content dd h1 {
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
  color: #041133;
}
.alter-window-mobile .alter-content dd p {
  margin-top: 0.02rem;
  margin-bottom: 0.28rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
}
.alter-window-mobile .alter-content dd .alter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.92rem;
  padding-right: 0.02rem;
}
.alter-window-mobile .alter-content dd .alter-form li:last-of-type {
  height: 1.12rem;
}
.alter-window-mobile .alter-content dd .alter-form .get-codes input {
  width: 50%;
}
.alter-window-mobile .alter-content dd .alter-form .get-codes p {
  flex: 1 1;
  text-align: right;
}
.alter-window-mobile .alter-content dd .alter-form li {
  width: 100%;
  height: 0.4rem;
  border: 0.01rem solid rgba(28, 41, 71, 0.1);
  outline: none;
  display: flex;
  padding: 0.08rem 0.1rem;
  background: #fff;
}
.alter-window-mobile .alter-content dd .alter-form li input {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #fff;
  font-size: 0.14rem;
}
.alter-window-mobile .alter-content dd .alter-form li textarea {
  flex: 1 1;
  height: 1.5rem;
  border: 0;
  outline: none;
  background: #fff;
  resize: none;
  font-size: 0.14rem;
}
.alter-window-mobile .alter-content dd .alter-form li input::-webkit-input-placeholder,
.alter-window-mobile .alter-content dd .alter-form li textarea::-webkit-input-placeholder {
  color: rgba(28, 41, 71, 0.45);
}
.alter-window-mobile .alter-content dd .alter-form li p {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  cursor: pointer;
}
.alter-window-mobile .alter-content dd .alter-form li span {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: rgba(28, 41, 71, 0.45);
}
.alter-window-mobile .alter-content dd .alter-form li:nth-of-type(even) {
  height: 0.16rem;
  font-size: 0.12rem;
  color: red;
  padding: 0;
  border: 0;
  background: none;
}
.alter-window-mobile .alter-content dd .btn-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0.12rem 0.2rem 0.3rem 0.2rem;
  box-shadow: 0px 0px 16px 0px rgba(4, 17, 51, 0.16);
  background: rgba(255, 255, 255, 0.9);
}
.alter-window-mobile .alter-content dd .btn-box button {
  width: 100%;
  height: 0.4rem;
  border-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2352d8;
  border: 0;
  color: #fff;
}
.alter-window-mobile .icon-close {
  position: absolute;
  top: 0.26rem;
  right: 0.24rem;
  font-size: 0.18rem;
  color: rgba(4, 17, 51, 0.65);
}
