.solution-scene-module1 {
  position: relative;
}
.solution-scene-module1 .item {
  padding-bottom: 0.8rem;
}
.solution-scene-module1 .item:nth-child(2n) {
  flex-direction: row-reverse;
}
.solution-scene-module1 .item .text-block {
  padding-top: 0.2rem;
}
.solution-scene-module1 .item ol li {
  padding-top: 0.14rem;
  display: flex;
  align-items: flex-start;
}
