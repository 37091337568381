.ai-main-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFang SC;
}
.ai-main-mobile .w1200 {
  margin-bottom: 0.3rem;
}
.ai-main-mobile .w1200 .ai-box {
  display: flex;
  margin-top: 0.26rem;
  flex-direction: column;
}
.ai-main-mobile .w1200 .title {
  text-align: center;
  margin-top: 0.3rem;
  color: #041133;
  font-size: 0.2rem;
  font-weight: 600;
}
.ai-main-mobile .w1200 h3 {
  margin-bottom: 0.16rem;
}
.ai-main-mobile .w1200 .left {
  padding: 0 0.2rem;
}
.ai-main-mobile .w1200 .right {
  flex: 1 1;
  padding-right: 0.2rem;
}
.ai-main-mobile .w1200 .right img {
  width: 100%;
}
.ai-main-mobile .w1200 li {
  font-weight: normal;
  letter-spacing: 0em;
  margin-bottom: 0.2rem;
  /* 远山黑/100 */
  color: #041133;
}
.ai-main-mobile .w1200 li::before {
  content: "";
  display: block;
  width: 0.06rem;
  height: 0.06rem;
  float: left;
  border-radius: 100%;
  background: rgba(4, 17, 51, 0.3);
  margin-top: 0.12rem;
  margin-right: 0.12rem;
}
