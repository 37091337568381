.layout-app-mobile {
  width: 100vw;
  height: 100%;
}
.layout-app-mobile header {
  width: 100vw;
  height: 0.48rem;
  position: fixed;
  top: 0;
  z-index: 999;
}
.layout-app-mobile header.black {
  background: #fff;
}
.layout-app-mobile header .contact-us span {
  display: none;
}
.layout-app-mobile header .head-content {
  height: 0.48rem;
  display: flex;
  padding: 0.16rem;
}
.layout-app-mobile header .head-content .left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.layout-app-mobile header .head-content .left img:nth-of-type(1) {
  width: 0.8rem;
  height: 0.2rem;
  display: block;
}
.layout-app-mobile header .head-content .left img:nth-of-type(2) {
  width: 0.28rem;
  height: 0.28rem;
  display: block;
}
.layout-app-mobile header .head-content ul {
  display: none;
}
.layout-app-mobile header .head-content img {
  display: none;
}
.layout-app-mobile main {
  flex: 1 1;
}
.layout-app-mobile footer {
  padding: 0;
  background-color: #000;
  position: relative;
  background-image: url(./images/bg.png);
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
}
.layout-app-mobile footer .footer-content {
  padding-bottom: 0.48rem;
}
.layout-app-mobile footer .footer-content .footer-logo {
  width: 1.26rem;
  height: 0.32rem;
  margin: 0.48rem 0.24rem;
}
.layout-app-mobile footer .footer-content .footer-content-quick-entry,
.layout-app-mobile footer .footer-content .footer-contact-us {
  height: 0.48rem;
  border: 0.01rem solid rgba(255, 255, 255, 0.1);
  border-left: 0;
  border-right: 0;
}
.layout-app-mobile footer .footer-content .footer-content-quick-entry dt,
.layout-app-mobile footer .footer-content .footer-contact-us dt {
  height: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  font-weight: normal;
  line-height: 0.24rem;
  color: #ffffff;
  padding: 0 0.24rem;
}
.layout-app-mobile footer .footer-content .footer-content-quick-entry dt::after,
.layout-app-mobile footer .footer-content .footer-contact-us dt::after {
  content: "";
  background: url(./images/arrow_right.png) no-repeat center;
  background-size: 0.06rem 0.12rem;
  height: 100%;
  width: 0.06rem;
  margin-left: auto;
}
.layout-app-mobile footer .footer-content .footer-content-quick-entry dd,
.layout-app-mobile footer .footer-content .footer-contact-us dd {
  display: none;
}
.layout-app-mobile footer .footer-qrcode {
  margin-top: 0.24rem;
}
.layout-app-mobile footer .footer-qrcode dt {
  display: none !important;
}
.layout-app-mobile footer .footer-qrcode dd {
  display: flex;
  padding-top: 0.24rem;
}
.layout-app-mobile footer .footer-qrcode dd .footer-qrcode-cot {
  margin-left: 0.24rem;
}
.layout-app-mobile footer .footer-qrcode dd img {
  display: block;
  width: 0.96rem;
  height: 0.96rem;
}
.layout-app-mobile footer .footer-qrcode dd p {
  margin-bottom: 0.08rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(255, 255, 255, 0.45);
}
.layout-app-mobile footer .footer-icons {
  margin-top: 0.3rem;
  margin-left: 0.24rem;
  display: flex;
  color: #fff;
}
.layout-app-mobile footer .footer-icons .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.1rem;
  width: 0.28rem;
  height: 0.28rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
}
.layout-app-mobile footer .footer-icons .icon-box:last-child {
  margin-right: 0;
}
.layout-app-mobile footer .footer-icons .icon-box .iconfont {
  color: #fff;
}
.layout-app-mobile footer .footer-copyright {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.14rem;
  text-align: center;
  line-height: 0.22rem;
  padding-bottom: 0.16rem;
}
.layout-app-mobile footer .footer-copyright a {
  font-size: 0.14rem;
}
.layout-app-mobile footer .model-content {
  margin-bottom: 0;
  padding-bottom: 0.16rem;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.layout-app-mobile .floating-window-mobile {
  position: fixed;
  right: 0.1rem;
  bottom: 0.48rem;
  z-index: 1003;
}
.layout-app-mobile .floating-window-mobile .floating-item-box {
  background: #ffffff;
  border-radius: 0.24rem;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
}
.layout-app-mobile .floating-window-mobile .floating-item-box li:first-child {
  border-radius: 0.24rem 0.24rem 0 0;
}
.layout-app-mobile .floating-window-mobile .floating-item-box li:last-child {
  border-radius: 0 0 0.24rem 0.24rem;
}
.layout-app-mobile .floating-window-mobile .floating-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.48rem;
  height: 0.48rem;
  background: #ffffff;
  cursor: pointer;
}
.layout-app-mobile .floating-window-mobile .floating-item .iconfont {
  font-size: 0.18rem;
}
.layout-app-mobile .floating-window-mobile .floating-item.floating-item-last {
  margin-top: 0.08rem;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
  border-radius: 50%;
}
.contact-textarea {
  display: flex;
  flex-direction: column;
}
.contact-textarea span {
  text-align: right;
}
.product-btn-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -0.48rem auto 0;
  margin-top: 0.01rem;
  width: 0.96rem;
  height: 0.36rem;
  border: 0.01rem solid #fff;
  color: #fff;
  font-size: 0.16rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  transition: all 0.3s 0.2s ease-in;
}
.product-btn-mobile::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease-in;
  width: 0;
}
.product-btn-mobile span {
  position: relative;
}
.product-btn-mobile:hover {
  color: #1c2947;
}
.product-btn-mobile:hover::before {
  width: 100%;
}
.contact-btn-mobile {
  width: 1.34rem;
  height: 0.36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #041133;
  font-size: 0.16rem;
  font-weight: 500;
  outline: none;
  border: 0;
  color: #ffffff;
  margin-top: 0rem;
  cursor: pointer;
  transition: all 0.3s 0.2s ease-in;
  position: relative;
}
.contact-btn-mobile::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #2352d8;
  transition: all 0.3s ease-in;
  width: 0;
}
.contact-btn-mobile span {
  position: relative;
}
.contact-btn-mobile:hover::before {
  width: 100%;
}
.o-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.44rem;
  border-radius: 0.04rem;
  background: #2352d8 !important;
  color: #fff !important;
}
