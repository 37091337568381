.fr-pc-about-us-module4 {
  padding-bottom: 0.5rem;
}
.fr-pc-about-us-module4 .honors {
  justify-content: center;
}
.fr-pc-about-us-module4 .honors .honor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.04rem;
  background: #eef1f7;
  padding: 0.15rem;
}
.fr-pc-about-us-module4 .honors .honor img {
  width: 100%;
  border-radius: 0.04rem;
}
.fr-pc-about-us-module4 .honors .honor .honor-black {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 0.7rem;
  padding: 0.2rem 0.24rem;
  border-radius: 0 0 0.04rem 0.04rem;
  background: linear-gradient(180deg, rgba(56, 59, 67, 0) 0%, #383b43 100%);
  text-align: center;
  color: #ffffff;
  font-size: 0.14rem;
}
.fr-pc-about-us-module4 .honors .honor:hover .honor-black {
  display: block;
}
