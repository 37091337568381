.solution-banner-mobile {
  margin-top: 0.52rem;
  top: 0;
  opacity: 1;
  text-align: center;
  background: #000000;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50vw auto;
}
.solution-banner-mobile .bg-box {
  padding: 0.2rem;
  height: 2rem;
  display: flex;
  align-items: center;
}
.solution-banner-mobile h3 {
  /* 摹小仙，让产品营销图触手可及 */
  opacity: 1;
  font-family: PingFang SC;
  font-size: 0.16rem;
  font-weight: 500;
  letter-spacing: 0em;
  margin: 0;
  /* 普白/100 */
  color: #ffffff;
}
.solution-banner-mobile p {
  opacity: 0.7;
  font-family: PingFang SC;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0em;
  margin-top: 0.04rem;
  /* 普白/100 */
  color: #ffffff;
  max-width: 60%;
  font-size: 0.08rem;
}
.solution-banner-mobile p span {
  font-size: 0.08rem;
}
