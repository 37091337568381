.fr-product-module1 {
  width: 100%;
  position: relative;
  min-width: 12rem;
  padding: 0 1.2rem;
  padding-top: 0.8rem;
}
.fr-product-module1 .title {
  font-size: 0.34rem;
  font-weight: 600;
  color: #041133;
  line-height: 0.48rem;
  margin-bottom: 0.12rem;
  text-align: center;
}
.fr-product-module1 .text {
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: #9e9e9e;
  white-space: pre-line;
  margin-bottom: 0.4rem;
  text-align: center;
}
.fr-product-module1 .pic {
  width: 11.06rem;
  margin: 0 auto;
  display: block;
}
