.pc-head {
  display: flex;
  height: 0.6rem;
  justify-content: center;
  padding: 0 0.24rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}
.pc-head .head-main {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.pc-head .head-main .left {
  display: flex;
  height: 100%;
  align-items: center;
}
.pc-head .head-main .logo {
  width: 1.1rem;
  height: 0.28rem;
}
.pc-head .head-main .navs {
  display: flex;
  height: 100%;
  margin: 0 0.8rem;
}
.pc-head .head-main .navs li {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.48rem;
  position: relative;
}
.pc-head .head-main .navs li a {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.16rem;
}
.pc-head .head-main .navs li::after {
  background: transparent;
  bottom: 0.08rem;
  content: "";
  height: 0.02rem;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.2rem;
}
.pc-head .head-main .navs li.navActive {
  font-weight: bold;
  position: relative;
}
.pc-head .head-main .navs li:hover .nav-children {
  display: block;
}
.pc-head .head-main .navs .nav-children {
  display: none;
  position: absolute;
  top: 0.6rem;
  left: 0;
  width: 1.7rem;
  border-radius: 0.08rem;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0 rgba(4, 17, 51, 0.16);
  padding: 0.04rem;
}
.pc-head .head-main .navs .nav-children .nav-children-title {
  line-height: 0.2rem;
  padding-top: 0.1rem;
  padding-left: 0.12rem;
  font-size: 0.12rem;
  color: #9e9e9e;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title) {
  border-radius: 0.04rem;
  width: 100%;
  height: 0.42rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 0.12rem;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title):first-child {
  border-top-left-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title):last-child {
  border-bottom-left-radius: 0.08rem;
  border-bottom-right-radius: 0.08rem;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title) a,
.pc-head .head-main .navs .nav-children li:not(.nav-children-title) em {
  width: 100%;
  height: 100%;
  line-height: 0.42rem;
  font-size: 0.14rem;
  font-weight: 400;
  color: #041133;
  font-style: normal;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title):hover {
  background-color: rgba(35, 82, 216, 0.05);
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title):not(.disabled):active,
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).active {
  background-color: #2352d8;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title):not(.disabled):active a,
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).active a {
  color: #fff;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).disabled {
  position: relative;
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).disabled a,
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).disabled em {
  cursor: not-allowed;
  color: rgba(4, 17, 51, 0.45);
}
.pc-head .head-main .navs .nav-children li:not(.nav-children-title).disabled:hover .coming-soon-tips {
  display: flex;
}
.coming-soon-tips {
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0.41rem;
  font-size: 0.12rem;
  color: #ffffff;
  line-height: 0.2rem;
  display: none;
  align-items: center;
  height: 0.28rem;
  padding: 0.04rem 0.08rem;
  background: rgba(17, 17, 17, 0.8);
  -webkit-backdrop-filter: blur(0.1rem);
  backdrop-filter: blur(0.1rem);
  border-radius: 0.04rem;
}
.coming-soon-tips::after {
  content: "";
  border-width: 0.04rem;
  border-color: rgba(17, 17, 17, 0.8) transparent transparent transparent;
  border-style: solid;
  position: absolute;
  bottom: -0.08rem;
  left: 50%;
  margin-left: -0.04rem;
}
