.product-banner {
  position: relative;
  width: 100%;
  padding-top: 34.7%;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-banner .p-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-banner .banner-content {
  position: absolute;
  top: 1.4rem;
  left: 1.2rem;
}
.product-banner .banner-content h1 {
  font-size: 0.5rem;
  font-weight: 600;
  color: #041133;
}
.product-banner .banner-content p {
  padding-right: 5.4rem;
  margin-top: 0.28rem;
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.8);
}
.product-banner .product-btn {
  position: absolute;
  left: 10.5%;
  top: 65%;
  width: 1.6rem;
  height: 0.56rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
