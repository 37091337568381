.common-banner.fr-product-marketing-banner {
  background-color: #f2fafd;
}
.common-banner.fr-product-marketing-banner .common-banner-main {
  height: 5rem;
}
.common-banner.fr-product-marketing-banner .common-banner-box {
  padding-top: 1.38rem;
}
@media screen and (min-width: 14.4rem) {
  .common-banner.fr-product-marketing-banner {
    background-size: cover;
  }
}
