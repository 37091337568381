.scence-pc-page.phone .solution-scene-module1 .item {
  padding: 0 0.2rem 0.4rem 0.2rem;
}
.scence-pc-page.phone .solution-scene-module1 .item .text-block {
  padding-top: 0;
  padding-bottom: 0.2rem;
}
.scence-pc-page.phone .solution-scene-module1 .item ol li {
  padding-top: 0.1rem;
}
