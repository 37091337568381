.solution-banner {
  margin-top: 60px;
  top: 0;
  height: 7rem;
  opacity: 1;
  text-align: center;
  background: #000000;
  background-repeat: no-repeat;
  background-position: right 36px top 54px ;
  background-size: auto 568.5px;
}
.solution-banner .bg-box {
  padding-top: 2rem;
  height: 7rem;
}
.solution-banner h3 {
  /* 摹小仙，让产品营销图触手可及 */
  width: 8.4rem;
  height: 0.84rem;
  opacity: 1;
  font-family: PingFang SC;
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0em;
  margin: 0;
  /* 普白/100 */
  color: #ffffff;
}
.solution-banner p {
  width: 9rem;
  height: 60px;
  opacity: 0.7;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
  letter-spacing: 0em;
  /* 普白/100 */
  color: #ffffff;
  margin-top: 30px;
}
