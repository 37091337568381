.news-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.6rem;
  background-color: #eef1f7;
}
.news-detail .detail-content {
  width: 100%;
  max-width: 14.4rem;
  padding: 0.24rem 0.48rem 0.75rem;
}
.news-detail .detail-content .bread-crumb {
  margin-bottom: 0.24rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.news-detail .detail-content .bread-crumb li {
  font-size: 0.14rem;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: #1c2947;
}
.news-detail .detail-content .detail-module {
  width: 100%;
  display: flex;
}
.news-detail .detail-content .detail-module .detail-left {
  width: 10rem;
  padding: 0 0.48rem;
  background: #ffffff;
  box-shadow: 0 0 0.24rem 0 rgba(28, 41, 71, 0.16);
  margin-right: 0.24rem;
}
.news-detail .detail-content .detail-module .detail-left .title {
  padding: 0.5rem 0 0;
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.4rem;
  color: #041133;
}
.news-detail .detail-content .detail-module .detail-left .infos {
  display: flex;
  padding: 0.12rem 0 0.24rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail .detail-content .detail-module .detail-left .infos .iconfont {
  margin-right: 0.08rem;
}
.news-detail .detail-content .detail-module .detail-left .infos .date {
  margin-right: 0.2rem;
}
.news-detail .detail-content .detail-module .detail-left .summary {
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail .detail-content .detail-module .detail-left .detail-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.24rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid rgba(28, 41, 71, 0.1);
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.45);
}
.news-detail .detail-content .detail-module .detail-left .detail-bottom .line {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  width: 1px;
  height: 12px;
  background: rgba(4, 17, 51, 0.1);
}
.news-detail .detail-content .detail-module .detail-left .detail-bottom .pre-item,
.news-detail .detail-content .detail-module .detail-left .detail-bottom .next-item {
  max-width: 1.94rem;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  font-size: 0.14rem;
  line-height: 0.22rem;
  cursor: pointer;
}
.news-detail .detail-content .detail-module .detail-left .detail-bottom .pre-item span,
.news-detail .detail-content .detail-module .detail-left .detail-bottom .next-item span {
  color: #041133;
}
.news-detail .detail-content .detail-module .detail-right {
  width: 3.2rem;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content {
  width: 100%;
  padding: 0.24rem;
  background-color: #fff;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content h2 {
  font-size: 0.24rem;
  font-weight: 600;
  line-height: 0.32rem;
  color: #1c2947;
  margin-bottom: 0.48rem;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content ul li {
  margin-bottom: 0.48rem;
  cursor: pointer;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content ul li img {
  width: 2.72rem;
  margin-bottom: 0.08rem;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content ul li h4 {
  margin-bottom: 0.08rem;
  font-size: 0.16rem;
  font-weight: 500;
  color: #1c2947;
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content ul li span {
  font-size: 0.12rem;
  font-weight: normal;
  color: rgba(28, 41, 71, 0.65);
}
.news-detail .detail-content .detail-module .detail-right .detail-right-content ul li:last-of-type {
  margin-bottom: 0;
}
.page-loading {
  opacity: 0.9;
}
.page-loading .loading-icon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(28, 41, 71, 0.15);
}
.page-loading .loading-icon::after {
  content: "";
  display: block;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 100%;
  border: 0.03rem solid #1c2947;
  border-top-color: transparent;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
