.layout-app-mobile .fr-bg-icon-tabs .line {
  height: 0.01rem;
}
.layout-app-mobile .fr-bg-icon-tabs .tabs-item {
  padding-bottom: 0.1rem;
}
.layout-app-mobile .fr-bg-icon-tabs .tabs-item .icon {
  height: 0.25rem;
  margin-bottom: 0.05rem;
}
.layout-app-mobile .fr-bg-icon-tabs .tabs-item .name {
  font-size: 0.12rem;
  line-height: 0.2rem;
}
