.home-pc-warp.phone .common-banner.home-pc-banner {
  background-position: bottom right;
}
.home-pc-warp.phone .home-module2 .home-module2-list .row {
  padding: 0.05rem 0.1rem 0.1rem 0.1rem;
}
.home-pc-warp.phone .home-module2 .home-module2-list .row:first-child {
  width: 100%;
}
.home-pc-warp.phone .home-module3 .module3-card {
  padding: 0.05rem 0.1rem 0.1rem 0.1rem;
}
.home-pc-warp.phone .home-module4 .module4-content {
  margin-top: 0.2rem;
  flex-direction: column;
}
.home-pc-warp.phone .home-module4 .module4-left {
  padding-top: 0.4rem;
  padding-left: 0.24rem;
  width: 100%;
  padding-bottom: 0.4rem;
}
.home-pc-warp.phone .home-module4 .module4-left .left-title {
  font-size: 0.12rem;
  margin-bottom: 0.04rem;
}
.home-pc-warp.phone .home-module4 .module4-left .left-big {
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.24rem;
}
.home-pc-warp.phone .home-module4 .module4-left .left-line {
  width: 0.16rem;
  height: 0.01rem;
  margin: 0.2rem 0;
}
.home-pc-warp.phone .home-module4 .module4-left .left-text {
  white-space: pre-line;
  line-height: 0.2rem;
  font-size: 0.12rem;
}
.home-pc-warp.phone .home-module4 .module4-list .module4-col:hover .col-main {
  background-color: transparent;
}
.home-pc-warp.phone .home-module4 .module4-list .module4-col:hover .hover-box {
  display: none;
}
.home-pc-warp.phone .home-module4 .module4-list .module4-col:hover .col-title {
  text-align: right;
}
.home-pc-warp.phone .home-module4 .module4-list .col-main {
  padding: 0.24rem 0.15rem 0.24rem 0;
}
.home-pc-warp.phone .home-module4 .module4-list .col-title {
  font-size: 0.12rem;
  line-height: 0.18rem;
}
