.phone.fr-pc-about-us .about-us-module2 .content {
  margin-top: 0.2rem;
}
.phone.fr-pc-about-us .about-us-module2 .content-md {
  padding-bottom: 0.25rem;
  flex-direction: column;
  padding: 0.2rem;
}
.phone.fr-pc-about-us .about-us-module2 .image-list {
  width: 100%;
  padding-bottom: 0.2rem;
}
.phone.fr-pc-about-us .about-us-module2 .right {
  padding-left: 0;
}
.phone.fr-pc-about-us .about-us-module2 .right-title {
  padding-bottom: 0.1rem;
}
.phone.fr-pc-about-us .about-us-module2 .right-title .tag {
  width: 0.34rem;
  margin-right: 0.06rem;
}
.phone.fr-pc-about-us .about-us-module2 .right-title .name {
  font-size: 0.16rem;
  line-height: 0.225rem;
}
.phone.fr-pc-about-us .about-us-module2 .right-text {
  font-size: 0.12rem;
  line-height: 0.2rem;
  padding-bottom: 0.16rem;
}
.phone.fr-pc-about-us .about-us-module2 .right-subtitle {
  font-size: 0.16rem;
  line-height: 0.225rem;
  padding-bottom: 0.12rem;
}
.phone.fr-pc-about-us .about-us-module2 .right-tags .tag {
  width: 20%;
  height: 0.3rem;
  border-radius: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0.12rem;
  font-family: zihun2hao-liliangcuheiti-Regular;
}
.phone.fr-pc-about-us .fr-pc-about-us-module4 {
  padding-bottom: 0.2rem;
}
.phone.fr-pc-about-us .fr-pc-about-us-module4 .honors {
  padding: 0.2rem;
  padding-top: 0;
}
.phone.fr-pc-about-us .fr-pc-about-us-module4 .honors .honor {
  padding: 0.08rem;
}
.phone.fr-pc-about-us .fr-pc-about-us-module4 .honors .honor img {
  width: 100%;
}
.phone.fr-pc-about-us .fr-pc-about-us-module4 .honors .honor .honor-black {
  height: 0.35rem;
  padding: 0.1rem 0.12rem;
  font-size: 0.12rem;
}
