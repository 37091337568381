.phone.join-us-pc-page .join-us-banner.common-banner .common-banner-main {
  height: 1.4rem;
  line-height: 1.4rem;
  font-size: 0.3rem;
}
.phone.join-us-pc-page .join-us-module1 .jobs-box {
  margin-top: 0.24rem;
  flex-direction: column;
}
.phone.join-us-pc-page .join-us-module1 .job-left {
  width: 100%;
  height: auto;
  padding-top: 0;
}
.phone.join-us-pc-page .join-us-module1 .job-left-content {
  flex-direction: row;
  padding: 0.24rem 0.12rem 0 0.12rem;
  width: 100%;
  flex-wrap: wrap;
  gap: 0;
}
.phone.join-us-pc-page .join-us-module1 .cla-name {
  font-size: 0.14rem;
  font-weight: normal;
  padding-right: 0.12rem;
}
.phone.join-us-pc-page .join-us-module1 .cla-name.active {
  font-weight: bold;
}
.phone.join-us-pc-page .join-us-module1 .job-box {
  overflow-x: auto;
  height: auto;
  width: 100%;
  padding-top: 0.12rem;
  padding-bottom: 0.24rem;
}
.phone.join-us-pc-page .join-us-module1 .job-box-content {
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: 0.12rem;
  display: flex;
}
.phone.join-us-pc-page .join-us-module1 .job-item {
  height: 0.3rem;
  border-radius: 0.3rem;
  padding: 0 0.12rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
}
.phone.join-us-pc-page .join-us-module1 .job-right {
  padding: 0.2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.phone.join-us-pc-page .join-us-module1 .job-head {
  flex-direction: column;
  padding-bottom: 0.1rem;
}
.phone.join-us-pc-page .join-us-module1 .job-name {
  font-size: 0.16rem;
}
.phone.join-us-pc-page .join-us-module1 .job-email {
  font-size: 0.16rem;
}
.phone.join-us-pc-page .join-us-module1 .job-cotent {
  padding-top: 0.2rem;
}
.phone.join-us-pc-page .join-us-module1 .job-row-name {
  font-size: 0.16rem;
  padding-top: 0.2rem;
}
.phone.join-us-pc-page .join-us-module1 .job-row-name:first-child {
  padding-top: 0;
}
.phone.join-us-pc-page .join-us-module1 .job-row-content {
  padding-top: 0.12rem;
}
.phone.join-us-pc-page .join-us-module1 .job-row-content,
.phone.join-us-pc-page .join-us-module1 .job-row-content p {
  font-size: 0.12rem;
  line-height: 0.2rem;
}
.phone.join-us-pc-page .join-us-module1 .job-row-content p {
  margin-bottom: 0.08rem;
}
.phone.join-us-pc-page .join-us-module2 {
  padding-bottom: 0.2rem;
}
.phone.join-us-pc-page .join-us-module2 .common-module-text {
  margin-bottom: 0.04rem;
}
.phone.join-us-pc-page .join-us-module2 .benefit-list {
  display: flex;
  padding: 0.24rem 0.04rem 0.04rem 0.04rem;
  flex-wrap: wrap;
}
.phone.join-us-pc-page .join-us-module2 .benefit-list li {
  width: calc(100% / 3);
  margin-bottom: 0.33rem;
}
.phone.join-us-pc-page .join-us-module2 .benefit-list li img {
  height: 0.24rem;
  margin-bottom: 0.04rem;
}
.phone.join-us-pc-page .join-us-module2 .benefit-list li p {
  font-size: 0.12rem;
  line-height: 0.2rem;
}
.phone.join-us-pc-page .common-banner.join-us-module3 {
  background-size: auto 100%;
}
.phone.join-us-pc-page .common-banner.join-us-module3 .common-banner-box {
  padding-top: 0.485rem;
}
.phone.join-us-pc-page .common-banner.join-us-module3 .common-banner-title {
  white-space: pre-line;
}
.phone.join-us-pc-page .common-banner.join-us-module3 .common-banner-text {
  font-size: 0.12rem;
  line-height: 0.2rem;
  font-weight: normal;
}
