.alter-window {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  overflow-x: hidden;
  overflow-y: auto;
  background-size: 100% 100%;
}
.alter-window .alter-content {
  height: 7rem;
  background-color: #fff;
  display: flex;
}
.alter-window .alter-content dt {
  width: 4.88rem;
  height: 7rem;
  background-color: #eef1f7;
  background-image: url("../../images/apply-left-bg.png");
  background-size: 4.88rem 7rem;
  background-repeat: no-repeat;
  background-position: top left;
  padding: 0.6rem 0.64rem;
}
.alter-window .alter-content dt h1 {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.54rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #1c2947;
}
.alter-window .alter-content dt b {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.54rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #1c2947;
}
.alter-window .alter-content dt p {
  font-size: 0.16rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #1c2947;
  margin-top: 0.32rem;
}
.alter-window .alter-content dt div {
  margin-top: 0.08rem;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.45);
}
.alter-window .alter-content dt img {
  margin-top: 2.1rem;
  width: 1.5rem;
  height: 1.5rem;
}
.alter-window .alter-content dd {
  width: 5.92rem;
  padding: 0.6rem 0.64rem;
  position: relative;
}
.alter-window .alter-content dd h1 {
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #1c2947;
}
.alter-window .alter-content dd p {
  margin-top: 0.04rem;
  margin-bottom: 0.2rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
}
.alter-window .alter-content dd .alter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alter-window .alter-content dd .alter-form li {
  width: 100%;
  height: 0.4rem;
  outline: none;
  display: flex;
  padding: 0.08rem 0.1rem;
  background-color: #fff;
  border: 1px solid rgba(4, 17, 51, 0.1);
  border-radius: 0.04rem;
}
.alter-window .alter-content dd .alter-form li input {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #fff;
  font-size: 0.14rem;
}
.alter-window .alter-content dd .alter-form li textarea {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #fff;
  resize: none;
  font-size: 0.14rem;
}
.alter-window .alter-content dd .alter-form li input::-webkit-input-placeholder,
.alter-window .alter-content dd .alter-form li textarea::-webkit-input-placeholder {
  color: rgba(28, 41, 71, 0.45);
  font-size: 0.14rem;
}
.alter-window .alter-content dd .alter-form li p {
  font-size: 0.16rem;
  font-weight: normal;
  letter-spacing: 0em;
  cursor: pointer;
}
.alter-window .alter-content dd .alter-form .contact-textarea {
  height: 1.28rem;
}
.alter-window .alter-content dd .alter-form .contact-textarea span {
  color: rgba(28, 41, 71, 0.45);
}
.alter-window .alter-content dd .alter-form .i-text {
  width: 100%;
  height: 0.16rem;
  font-size: 10px;
  color: red;
  padding: 0;
  border: 0;
  background: none;
}
.alter-window .alter-content dd .phone-box {
  display: flex;
  flex-wrap: wrap;
}
.alter-window .alter-content dd .phone-box li {
  position: relative;
  width: 2.24rem;
}
.alter-window .alter-content dd .phone-box li:first-child {
  margin-right: 0.16rem;
}
.alter-window .alter-content dd .phone-box li.code-box input {
  flex: 0 1;
  width: 1.12rem;
}
.alter-window .alter-content dd .phone-box li.code-box span {
  position: absolute;
  right: 0.04rem;
  width: 0.9rem;
  font-size: 0.14rem;
  color: #2352d8;
}
.alter-window .alter-content dd .agreement-box {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.65);
}
.alter-window .alter-content dd .agreement-box .read-span {
  color: #2352d8;
  font-size: 0.14rem;
  cursor: pointer;
}
.alter-window .alter-content dd .agreement-box .my-checkbox {
  width: 0.2rem;
  height: 0.22rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.alter-window .alter-content dd .agreement-box .my-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.alter-window .alter-content dd .agreement-box .my-checkbox .my-checkbox-inner {
  padding-left: 0.01rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.alter-window .alter-content dd .agreement-box .my-checkbox .my-checkbox-inner img {
  width: 0.14rem;
  height: 0.14rem;
}
.alter-window .alter-content dd .submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.28rem;
}
.alter-window .alter-content dd .submit-btn button {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.12rem 0.96rem;
  background: #2352d8;
  border: 0;
  outline: none;
  font-family: SourceHanSansCN-Bold;
  font-size: 0.16rem;
  font-weight: 500;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
}
