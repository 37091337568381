.ground-form-mobile {
  position: absolute;
  bottom: 0.48rem;
  right: 0rem;
  width: 100%;
  padding: 0 0.16rem;
}
.ground-form-mobile .ground-form-content {
  background-color: #fff;
  padding: 0.24rem;
}
.ground-form-mobile .ground-form-content .ground-form-header {
  height: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 0.16rem;
}
.ground-form-mobile .ground-form-content .ground-form-header .ground-from-message {
  position: absolute;
  width: 2.18rem;
  height: 0.4rem;
  justify-content: center;
  padding: 0.08rem 0.16rem;
  border-radius: 0.74rem;
  background: rgba(241, 69, 69, 0.1);
  border: 0.01rem solid #F14545;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(0.08rem);
  backdrop-filter: blur(0.08rem);
  top: -0.08rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  display: flex;
  align-items: center;
}
.ground-form-mobile .ground-form-content .ground-form-header .ground-from-message i {
  display: block;
  width: 0.2rem;
  height: 0.2rem;
  background-image: url('../images/errorIcon.png');
  background-size: 100% 100%;
}
.ground-form-mobile .ground-form-content .ground-form-header .ground-from-message p {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #F14545;
  margin-left: 0.1rem;
}
.ground-form-mobile .ground-form-content .ground-form-header .form-header-size {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.18rem;
  font-weight: 600;
  line-height: 0.26rem;
  letter-spacing: 0em;
  color: #041133;
}
.ground-form-mobile .ground-form-content .ground-form-header img {
  position: absolute;
  width: 1rem;
  height: 0.92rem;
  right: 0.16rem;
  bottom: 0;
}
.ground-form-mobile .ground-form-content .ground-form-list {
  display: flex;
  flex-direction: column;
}
.ground-form-mobile .ground-form-content .ground-form-list .ground-form-submit {
  border: 0;
  justify-content: center;
  margin-top: 0.32rem;
}
.ground-form-mobile .ground-form-content .ground-form-list .ground-form-submit button {
  width: 2.4rem;
  height: 0.48rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.08rem 0rem;
  border-radius: 0.9rem;
  background: #2352D8;
  outline: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-family: SourceHanSansSC-Medium;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0em;
}
.ground-form-mobile .ground-form-content .ground-form-list .ground-get-code input {
  width: 0.1rem;
}
.ground-form-mobile .ground-form-content .ground-form-list .ground-get-code div {
  width: 0.8rem;
  height: 0.23rem;
}
.ground-form-mobile .ground-form-content .ground-form-list li {
  height: 0.4rem;
  border: 0.01rem solid rgba(4, 17, 51, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.16rem;
  margin-bottom: 0.08rem;
}
.ground-form-mobile .ground-form-content .ground-form-list li input {
  flex: 1 1;
  border: 0;
  height: 100%;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  outline: none;
}
.ground-form-mobile .ground-form-content .ground-form-list li div {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.16rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #2352D8;
  cursor: pointer;
}
.ground-form-mobile .ground-form-content .ground-from-success {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 0.16rem;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.45);
  -webkit-backdrop-filter: blur(0.24rem);
  backdrop-filter: blur(0.24rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding .ground-success-content {
  width: 2.45rem;
  height: 2.28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.24rem;
  border-radius: 0.08rem;
  background: rgba(0, 181, 120, 0.1);
  box-sizing: border-box;
  border: 0.01rem solid #00B578;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding .ground-success-content i {
  display: block;
  width: 0.48rem;
  height: 0.48rem;
  background-image: url('../images/calendar-check-fill.png');
  background-size: 100% 100%;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding .ground-success-content div {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.24rem;
  font-weight: normal;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #00B578;
  margin-top: 0.24rem;
  margin-bottom: 0.08rem;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding .ground-success-content span {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.12rem;
  font-weight: normal;
  line-height: 0.2rem;
  letter-spacing: 0em;
  color: #00B578;
}
.ground-form-mobile .ground-form-content .ground-from-success .ground-from-padding .ground-success-content p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.16rem;
  font-weight: normal;
  line-height: 0.24rem;
  letter-spacing: 0em;
  text-decoration: underline;
  color: #00B578;
  cursor: pointer;
  margin-top: 0.24rem;
}
