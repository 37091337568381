.center-content {
  height: 100vh;
  display: flex;
  justify-content: center;
}
.center-content .contact-content {
  max-width: 6rem;
  padding: 0 0.24rem;
}
.center-content .contact-content .contact-logo {
  height: 0.8rem;
  margin-bottom: 0.24rem;
  background-image: url('./images/logo.png');
  background-repeat: no-repeat;
  background-position: 0 0.24rem;
  background-size: 1.2618rem 0.3rem;
}
.center-content .contact-content h1 {
  height: 0.68rem;
  font-family: SourceHanSansCN-Bold;
  font-size: 0.18rem;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #1C2947;
  background-image: url('./images/joinUsIcon.png');
  background-repeat: no-repeat;
  background-size: 0.89rem 0.58rem;
  background-position: bottom right;
  display: flex;
  font-weight: 600;
  align-items: center;
  margin: 0;
}
.center-content .contact-content .contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-content .contact-content .contact-form li:last-of-type {
  height: 1.12rem;
}
.center-content .contact-content .contact-form .get-codes input {
  width: 50%;
}
.center-content .contact-content .contact-form .get-codes p {
  flex: 1 1;
  text-align: right;
}
.center-content .contact-content .contact-form li {
  width: 100%;
  height: 0.4rem;
  border: 0.01rem solid rgba(28, 41, 71, 0.1);
  outline: none;
  display: flex;
  padding: 0.08rem 0.1rem;
  background: #EEF1F7;
}
.center-content .contact-content .contact-form li input {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #EEF1F7;
  font-size: 0.14rem;
}
.center-content .contact-content .contact-form li textarea {
  flex: 1 1;
  border: 0;
  outline: none;
  background: #EEF1F7;
  resize: none;
  font-size: 0.14rem;
}
.center-content .contact-content .contact-form li p {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  cursor: pointer;
}
.center-content .contact-content .contact-form li span {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: rgba(28, 41, 71, 0.45);
}
.center-content .contact-content .contact-form li:nth-of-type(even) {
  height: 0.16rem;
  font-size: 0.12rem;
  color: red;
  padding: 0;
  border: 0;
  background: none;
}
.center-content .contact-content button {
  margin-top: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.4rem;
  border: 0;
  font-family: SourceHanSansCN-Bold;
  font-size: 0.16rem;
  font-weight: 500;
  color: #FFFFFF;
  background: #2352D8;
}
