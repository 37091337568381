.common-banner.solution-scene-banner .common-banner-main {
  height: 6rem;
}
.common-banner.solution-scene-banner.all {
  background-color: #4c6575;
}
.common-banner.solution-scene-banner.expert {
  background-color: #a4b09a;
}
.common-banner.solution-scene-banner.private {
  background-color: #252e42;
}
@media screen and (min-width: 19.4rem) {
  .common-banner.solution-scene-banner.all {
    background-size: cover;
    background-position-y: center;
  }
  .common-banner.solution-scene-banner.expert {
    background-size: cover;
  }
  .common-banner.solution-scene-banner.private {
    background-size: cover;
    background-position-y: center;
  }
}
