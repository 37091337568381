.fr-bg-icon-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 0.01rem solid rgba(4, 17, 51, 0.1);
}
.fr-bg-icon-tabs .line {
  position: absolute;
  padding: 0 0.2rem;
  width: 20%;
  height: 0.024rem;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  pointer-events: none;
}
.fr-bg-icon-tabs .line::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.012rem;
  background: #2352d8;
}
.fr-bg-icon-tabs .tabs-item {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-bottom: 0.22rem;
}
.fr-bg-icon-tabs .tabs-item .icon {
  height: 0.5rem;
  margin-bottom: 0.1rem;
}
.fr-bg-icon-tabs .tabs-item .name {
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
}
