.attention-modal-mobile {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(28, 41, 71, 0.8);
  z-index: 1004;
}
.attention-modal-mobile .attention-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.24rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.2rem 0.2rem 0 0;
}
.attention-modal-mobile .attention-content .attention-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.attention-modal-mobile .attention-content .attention-content-header .left {
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
  color: #041133;
}
.attention-modal-mobile .attention-content .attention-content-header .icon-close {
  font-size: 0.18rem;
  color: rgba(4, 17, 51, 0.65);
}
.attention-modal-mobile .attention-content .code-img {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.28rem auto 0.12rem;
}
.attention-modal-mobile .attention-content p {
  margin-bottom: 0.56rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: rgba(4, 17, 51, 0.45);
  text-align: center;
}
