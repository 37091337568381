.product-banner-mobile {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.product-banner-mobile .banner-content {
  position: absolute;
  top: 0.36rem;
  left: 0.24rem;
}
.product-banner-mobile .banner-content h1 {
  font-size: 0.2rem;
  font-weight: 600;
  color: #041133;
}
.product-banner-mobile .banner-content p {
  padding-right: 0.24rem;
  margin-top: 0.08rem;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: rgba(4, 17, 51, 0.8);
}
