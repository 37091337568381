.ground-page {
  /* 头部header */
  /* 顶部banner */
  /* ForeSight | 产品参谋 */
  /* 面向企业的创新业务场景解决方案 */
  /* 添加微信群 */
  /* 底部banner */
}
.ground-page .ground-header {
  height: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.24rem;
}
.ground-page .ground-header .ground-logo {
  width: 1.27rem;
  height: 0.32rem;
}
.ground-page .ground-header .ground-contact {
  width: 2.364rem;
  height: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ground-page .ground-header .ground-contact img {
  width: 1.97rem;
  height: 0.32rem;
  -webkit-animation: scaleDraw 5s ease-in-out infinite;
  animation: scaleDraw 5s ease-in-out infinite;
}
@-webkit-keyframes scaleDraw {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes scaleDraw {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.ground-page .ground-banner {
  height: 5.4rem;
  background-image: url('./images/bgBanner.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.ground-page .ground-banner .ground-banner-content {
  width: 12rem;
  height: 100%;
  padding-top: 1.18rem;
  position: relative;
}
.ground-page .ground-banner .ground-banner-content .ground-title-children {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.4rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.45);
  margin-bottom: 0.24rem;
}
.ground-page .ground-banner .ground-banner-content .ground-title-children span {
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.4rem;
  letter-spacing: 0em;
  color: #2352d8;
}
.ground-page .ground-banner .ground-banner-content .ground-title {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.64rem;
  font-weight: normal;
  line-height: 0.76rem;
  letter-spacing: 0em;
  margin-bottom: 0.24rem;
}
.ground-page .ground-banner .ground-banner-content .ground-title span {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.64rem;
  font-weight: normal;
  line-height: 0.96rem;
  letter-spacing: 0em;
  background-color: #2352d8;
  color: #fff;
}
.ground-page .ground-banner .ground-banner-content p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.16rem;
  font-weight: normal;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.65);
}
.ground-page .fore-sight {
  height: 12.7rem;
  padding-top: 0.96rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page .fore-sight .fore-sight-title {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
  letter-spacing: 0em;
  color: #041133;
  margin-bottom: 0.48rem;
}
.ground-page .fore-sight .fore-sight-top {
  width: 12rem;
  padding-bottom: 0.8rem;
}
.ground-page .fore-sight .fore-sight-top img {
  width: 100%;
  height: 3.64rem;
}
.ground-page .fore-sight .fore-sight-top p {
  margin-top: 0.48rem;
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: rgba(4, 17, 51, 0.65);
}
.ground-page .fore-sight .fore-sight-bottom {
  width: 12rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.ground-page .fore-sight .fore-sight-bottom img:nth-of-type(1) {
  width: 7.07rem;
  height: 4.18rem;
}
.ground-page .fore-sight .fore-sight-bottom img:nth-of-type(2) {
  width: 4.96rem;
  height: 3.16rem;
}
.ground-page .ground-solution {
  height: 8.52rem;
  background-image: url('./images/bgSolution.jpeg');
  background-size: 100% 100%;
  padding-top: 0.96rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ground-page .ground-solution .ground-solution-header {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
  letter-spacing: 0em;
  color: #041133;
  margin-bottom: 0.48rem;
}
.ground-page .ground-solution .ground-solution-content {
  width: 12rem;
  display: flex;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style {
  flex: 1 1;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style img {
  width: 100%;
  height: 3.2rem;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style div {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 600;
  line-height: 0.32rem;
  letter-spacing: 0em;
  margin-top: 0.32rem;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style div span {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 600;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #2352D8;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style div i {
  display: none;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style p {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.16rem;
  font-weight: 600;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: #041133;
  margin-top: 0.08rem;
  margin-bottom: 0.24rem;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style .contact-btn {
  background-color: transparent;
  color: #041133;
  border: 0.01rem solid #041133;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style .contact-btn::before {
  background-color: #2352d8;
  transition: all 0.3s ease-in;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style .contact-btn:hover::before {
  width: 100%;
}
.ground-page .ground-solution .ground-solution-content .solution-all-style .contact-btn:hover span {
  color: #fff;
}
.ground-page .ground-solution .ground-solution-content .ground-solution-left {
  margin-right: 0.48rem;
}
.ground-page .ground-solution-advertisement {
  width: 100%;
  height: 3.02rem;
  background-image: url('./images/frontisBg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content {
  width: 12rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left div {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.08rem;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left div span {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.08rem;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left p {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.32rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-bottom: 0.26rem;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-left span {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right img {
  width: 1.76rem;
  height: 1.76rem;
}
.ground-page .ground-solution-advertisement .ground-advertisement-content .ground-advertisement-right p {
  width: 1.76rem;
  text-align: center;
  font-family: SourceHanSansSC-Medium;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-top: 0.08rem;
}
.ground-page .ground-footer {
  height: 5.4rem;
  background-image: url('./images/footerBg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.ground-page .ground-footer .ground-footer-content {
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-text {
  display: flex;
  align-items: flex-end;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-text i {
  display: block;
  width: 0.5rem;
  height: 1.2rem;
  background-image: url('./images/free.png');
  background-size: 100% 100%;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-text div p {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.56rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-text div span {
  font-family: zihun2hao-liliangcuheiti-Regular;
  font-size: 0.48rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list {
  width: 5.15rem;
  height: 0.1314rem;
  display: flex;
  margin-top: 0.32rem;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list li:nth-of-type(1) {
  justify-content: flex-start;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list li:nth-of-type(3) {
  border-right: 0;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list li {
  flex: 1 1;
  display: flex;
  align-items: center;
  border-right: 0.02rem solid rgba(255, 255, 255, 0.65);
  justify-content: center;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list li img {
  width: 0.2rem;
  height: 0.18rem;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-list li p {
  font-family: SourceHanSansSC-Medium;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-left: 0.1rem;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-play {
  width: 4.81rem;
  height: 0.54rem;
  padding: 0.16rem 0.24rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: 0.64rem;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading {
  display: flex;
  align-items: center;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading p {
  font-family: SourceHanSansSC-Regular;
  font-size: 0.14rem;
  font-weight: normal;
  line-height: 0.22rem;
  letter-spacing: 0em;
  color: #FFFFFF;
  margin-right: 0.16rem;
}
.ground-page .ground-footer .ground-footer-content .ground-footer-play .ground-play-pading img {
  width: 0.25rem;
  height: 0.13rem;
}
