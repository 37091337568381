.innovate-mobile {
  overflow-x: hidden;
}
.innovate-mobile .innovate-content {
  width: 100% !important;
  padding: 0 0.24rem !important;
}
.innovate-mobile .innovate-content h2 {
  margin-top: 0.4rem !important;
  font-size: 0.18rem !important;
}
.innovate-mobile .innovate-content p {
  margin-top: 0.1rem !important;
  margin-bottom: 0.2rem;
  font-size: 0.12rem !important;
  line-height: 0.18rem !important;
}
.innovate-mobile .innovate-content .innovate-img {
  width: 100% !important;
  height: auto !important;
}
