.product-highlights {
  width: 12rem;
  margin: 0 auto;
}
.product-highlights h2 {
  padding: 0.8rem 0 0.96rem;
  font-size: 0.34rem;
  font-weight: 600;
  text-align: center;
  color: #041133;
}
.product-highlights .lights-box {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(4, 17, 51, 0.1);
}
.product-highlights .lights-box .light-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.22rem;
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.28rem;
  color: #041133;
  cursor: pointer;
}
.product-highlights .lights-box .light-item img {
  margin-bottom: 0.1rem;
  width: 0.5rem;
  height: 0.5rem;
}
.product-highlights .lights-box .light-item.active {
  position: relative;
}
.product-highlights .lights-box .light-item.active::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1.2rem;
  height: 0.024rem;
  border-radius: 0.02rem;
  background: #2352D8;
}
.product-highlights .light-content {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 0.8rem;
}
.product-highlights .light-content .left {
  padding-top: 0.5rem;
  width: 3.8rem;
}
.product-highlights .light-content .left .title {
  margin-bottom: 0.32rem;
  font-size: 0.28rem;
  font-weight: 500;
  line-height: 0.32rem;
  color: #041133;
}
.product-highlights .light-content .left .texts-box .text {
  margin-bottom: 0.2rem;
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: #041133;
}
.product-highlights .light-content .left .texts-box .text:last-child {
  margin-bottom: 0;
}
.product-highlights .light-content .left .texts-box .text .iconfont {
  margin-right: 0.12rem;
  color: #32BFA1;
}
.product-highlights .light-content .left .product-btn {
  margin: 0;
  margin-top: 0.4rem;
  width: 1.24rem;
  height: 0.44rem;
  border-radius: 0.04rem;
  background: #2352D8;
}
.product-highlights .light-content .left .product-btn::before {
  display: none;
}
.product-highlights .light-content .left .product-btn:hover {
  color: #fff;
  background: rgba(35, 82, 216, 0.8);
}
.product-highlights .light-content .left .product-btn:active {
  background: #1c42ad;
}
.product-highlights .light-content .left .alter-window {
  width: 100vw;
  height: 100vh;
  left: 0;
}
.product-highlights .light-content .right {
  width: 7.5rem;
  height: auto;
  margin-left: 0.3rem;
}
