.fr-product-banner {
  width: 100%;
  min-width: 12rem;
  padding-top: 0.6rem;
  height: 5.6rem;
}
.fr-product-banner .bg {
  width: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 5rem;
  background-size: cover;
}
.fr-product-banner .content {
  max-width: 12rem;
  margin: 0 auto;
  padding-top: 1.38rem;
}
.fr-product-banner .title {
  font-size: 0.5rem;
  font-weight: 600;
  color: #041133;
  line-height: 0.62rem;
  margin-bottom: 0.31rem;
  white-space: pre-line;
}
.fr-product-banner .text {
  font-size: 0.16rem;
  line-height: 0.24rem;
  color: rgba(4, 17, 51, 0.8);
  white-space: pre-line;
}
