.contact-btn {
  width: 1.92rem;
  height: 0.56rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #041133;
  font-size: 0.16rem;
  font-weight: 500;
  outline: none;
  border: 0;
  color: #ffffff;
  margin-top: 0.64rem;
  cursor: pointer;
  transition: all 0.3s 0.2s ease-in;
  position: relative;
}
.contact-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #2352d8;
  transition: all 0.3s ease-in;
  width: 0;
}
.contact-btn span {
  position: relative;
}
.contact-btn:hover::before {
  width: 100%;
}
.product-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -0.48rem auto 0;
  margin-top: 0.01rem;
  width: 1.92rem;
  height: 0.55rem;
  border: 0.01rem solid #fff;
  color: #fff;
  font-size: 0.16rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  transition: all 0.3s 0.2s ease-in;
}
.product-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease-in;
  width: 0;
}
.product-btn span {
  position: relative;
}
.product-btn:hover {
  color: #1c2947;
}
.product-btn:hover::before {
  width: 100%;
}
.layout-app {
  width: 100%;
  height: 100%;
  min-width: 12rem;
}
.layout-app header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0.6rem;
  padding: 0 1.2rem;
  display: flex;
  justify-content: center;
  z-index: 1002;
}
.layout-app header .logo {
  width: 1.1rem;
  height: 0.28rem;
}
.layout-app header .contact-us {
  width: 1.12rem;
  height: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.04rem;
  border: 1px solid #2352d8;
  color: #2352d8;
  font-size: 0.16rem;
  line-height: 0.24rem;
  cursor: pointer;
}
.layout-app header .head-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.layout-app header .head-content .left {
  display: flex;
  align-items: center;
  height: 100%;
}
.layout-app header .head-content .navs {
  display: flex;
  height: 100%;
  margin: 0 0.8rem;
}
.layout-app header .head-content .navs li {
  position: relative;
  margin-right: 0.48rem;
}
.layout-app header .head-content .navs li a {
  display: flex;
  align-items: center;
  color: #041133;
  height: 100%;
}
.layout-app header .head-content .navs li .nav-children {
  display: none;
  position: absolute;
  top: 0.6rem;
  left: 0;
  width: 1.7rem;
  border-radius: 0.08rem;
  background: #ffffff;
  box-shadow: 0 0 16px 0 rgba(4, 17, 51, 0.16);
}
.layout-app header .head-content .navs li .nav-children li {
  width: 100%;
  height: 0.42rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 0.12rem;
}
.layout-app header .head-content .navs li .nav-children li:first-child {
  border-top-left-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
}
.layout-app header .head-content .navs li .nav-children li:last-child {
  border-bottom-left-radius: 0.08rem;
  border-bottom-right-radius: 0.08rem;
}
.layout-app header .head-content .navs li .nav-children li a {
  width: 100%;
  height: 100%;
  line-height: 0.42rem;
  font-size: 0.14rem;
  font-weight: 400;
  color: #041133;
}
.layout-app header .head-content .navs li .nav-children li:hover {
  background-color: #2352d8;
}
.layout-app header .head-content .navs li .nav-children li:hover a {
  color: #fff;
}
.layout-app header .head-content .navs li.navActive {
  position: relative;
  color: #fff;
  font-weight: 500;
}
.layout-app header .head-content .navs li.navActive::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0.08rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.2rem;
  height: 0.02rem;
  background: #041133;
}
.layout-app header .head-content .navs li.navActive:hover .nav-children {
  display: block;
}
.layout-app header .head-content img:nth-of-type(2) {
  display: none;
}
.layout-app header .head-content img:nth-of-type(3) {
  margin-right: 0.09rem;
}
.layout-app header .head-content img:last-of-type {
  cursor: pointer;
}
.layout-app header.white {
  background-color: rgba(255, 255, 255, 0);
}
.layout-app header.black {
  background-color: #ffffff;
}
.layout-app header.black .head-content .left .navs li a {
  color: #041133;
}
.layout-app header.black .head-content .left .navs li.navActive::after {
  background: #041133;
}
.layout-app header.black .head-content .contact-us {
  border-color: #2352d8;
  color: #2352d8;
}
.layout-app footer {
  width: 100%;
  position: relative;
  bottom: 0;
  box-sizing: border-box;
  background-image: url(./images/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.layout-app footer .footer-content {
  position: relative;
  max-width: 14.4rem;
  padding: 0.5rem 1.2rem;
  display: flex;
  margin: 0 auto;
}
.layout-app footer .footer-logo {
  width: 1.26rem;
  height: 0.32rem;
  margin-right: 1.78rem;
}
.layout-app footer dl {
  margin-bottom: 0;
}
.layout-app footer dl dt {
  font-size: 0.24rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.48rem;
  line-height: 0.32rem;
  height: 0.32rem;
}
.layout-app footer dl p {
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #ffffff;
  margin-bottom: 0.16rem;
}
.layout-app footer dl p:last-child {
  margin-bottom: 0;
}
.layout-app footer dl:nth-of-type(1) dd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.layout-app footer .footer-content-quick-entry {
  width: 2.4rem;
  margin-right: 0.16rem;
}
.layout-app footer .footer-contact-us {
  width: 3.6rem;
  margin-right: 0.16rem;
}
.layout-app footer .footer-qrcode {
  margin-left: auto;
}
.layout-app footer .footer-qrcode dd {
  display: flex;
}
.layout-app footer .footer-qrcode dd .footer-qrcode-cot {
  margin-right: 0.24rem;
}
.layout-app footer .footer-qrcode dd .footer-qrcode-cot:last-child {
  margin-right: 0;
}
.layout-app footer .footer-qrcode dd img {
  width: 1.22rem;
  height: 1.22rem;
}
.layout-app footer .footer-qrcode dd p {
  font-size: 0.16rem;
  font-weight: normal;
  color: #ffffff;
  line-height: 0.2rem;
  margin-top: 0.08rem;
}
.layout-app footer .footer-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.43rem;
  font-size: 0.16rem;
  color: rgba(255, 255, 255, 0.5);
  border-top: 0.01rem solid rgba(255, 255, 255, 0.3);
}
.layout-app footer .model-content {
  margin-bottom: 0;
  padding-bottom: 0.12rem;
  font-size: 0.14rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.layout-app footer .footer-icons {
  position: absolute;
  bottom: 0.64rem;
  left: 1.2rem;
  display: flex;
  color: #fff;
}
.layout-app footer .footer-icons .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.1rem;
  width: 0.28rem;
  height: 0.28rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
}
.layout-app footer .footer-icons .icon-box:last-child {
  margin-right: 0;
}
.layout-app footer .footer-icons .icon-box .iconfont {
  color: #fff;
}
.layout-app footer .footer-icons .weibo-code-chart {
  position: absolute;
  left: 0;
  bottom: 0.46rem;
  width: 1.68rem;
  height: 1.92rem;
  border-radius: 0.04rem;
  padding: 0.2rem 0.24rem 0.24rem 0.24rem;
  background: #ffffff;
}
.layout-app footer .footer-icons .weibo-code-chart dt {
  margin-bottom: 0;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
  text-align: center;
}
.layout-app footer .footer-icons .weibo-code-chart dd {
  width: 1.2rem;
  height: 1.2rem;
  background-size: 100% 100%;
}
.layout-app .floating-window {
  position: fixed;
  bottom: 0.3rem;
  right: 0.1rem;
  width: 0.6rem;
  height: 2.84rem;
  z-index: 555;
}
.layout-app .floating-window .operators {
  padding: 0.1rem 0;
  border-radius: 0.3rem;
  background: #fff;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
}
.layout-app .floating-window .operator {
  width: 0.6rem;
  height: 0.64rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.layout-app .floating-window .operator .iconfont {
  font-size: 0.18rem;
}
.layout-app .floating-window .operator .text {
  font-size: 0.12rem;
  line-height: 0.2rem;
  letter-spacing: -0.06em;
  color: rgba(4, 17, 51, 0.65);
}
.layout-app .floating-window .operator .iconfont-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.28rem;
  height: 0.28rem;
  border-radius: 50%;
}
.layout-app .floating-window .go-top {
  width: 0.62rem;
  height: 0.62rem;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
  cursor: pointer;
}
.layout-app .floating-window .go-top .iconfont {
  font-size: 0.22rem;
}
.layout-app .floating-window .floating-active .iconfont-box {
  background: rgba(35, 82, 216, 0.1);
}
.layout-app .floating-window .floating-active .iconfont-box .iconfont {
  color: #2352d8;
}
.code-chart {
  position: fixed;
  bottom: 0.3rem;
  right: 0.88rem;
  z-index: 1003;
  width: 1.68rem;
  height: 1.92rem;
  border-radius: 0.04rem;
  padding: 0.2rem 0.24rem 0.24rem 0.24rem;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
}
.code-chart dt {
  margin-bottom: 0.08rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
  text-align: center;
}
.code-chart dd {
  width: 1.2rem;
  height: 1.2rem;
  background-size: 100% 100%;
}
.pre-sale {
  position: fixed;
  bottom: 0.3rem;
  right: 0.88rem;
  z-index: 1003;
  width: 1.68rem;
  height: 2.78rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0.04rem;
  padding: 0.16rem 0.24rem;
  box-shadow: 0 0 0.16rem 0 rgba(0, 15, 53, 0.16);
}
.pre-sale dt {
  margin-bottom: 0.08rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: rgba(4, 17, 51, 0.45);
  text-align: center;
}
.pre-sale dd img {
  width: 1.2rem;
  height: 1.2rem;
}
.pre-sale dd p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.16rem;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.22rem;
  color: #041133;
}
.pre-sale dd p .contract-icon {
  margin-right: 0.06rem;
  width: 0.16rem;
  height: 0.16rem;
}
.pre-sale dd .btn {
  margin-top: 0.2rem;
  width: 1.28rem;
  height: 0.36rem;
  border-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2352d8;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .layout-app header {
    padding: 0;
  }
  .layout-app header .head-main {
    max-width: 12rem;
    margin: 0 auto;
  }
}
