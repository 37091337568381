.join-us-module1 {
  background-color: #eef1f7;
}
.join-us-module1 .jobs-box {
  display: flex;
  background: #ffffff;
  max-width: 13.44rem;
  margin: 0 auto;
  margin-top: 0.48rem;
}
.join-us-module1 .job-left {
  width: 2.4rem;
  background: #2352d8;
  color: #fff;
  height: 5.84rem;
  padding-top: 0.48rem;
}
.join-us-module1 .job-left-main {
  width: 100%;
  color: #fff;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
.join-us-module1 .job-left-content {
  display: flex;
  flex-direction: column;
  gap: 0.24rem;
  padding: 0 0.24rem 0.48rem 0.24rem;
}
.join-us-module1 .cla-name {
  font-size: 0.16rem;
  font-weight: bold;
  color: #ffffff;
}
.join-us-module1 .job-item {
  height: 0.39rem;
  border-radius: 0.91rem;
  padding: 0 0.24rem;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1);
  line-height: 0.39rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 0.16rem;
}
.join-us-module1 .job-item.active {
  background: #ffffff;
  font-weight: bold;
  color: #2352d8;
}
.join-us-module1 .job-item + .cla-name {
  padding-top: 0.32rem;
}
.join-us-module1 .job-right {
  flex: 1 1;
  padding: 0.48rem;
  padding-bottom: 0;
  padding-right: 0;
  height: 5.84rem;
  overflow: hidden;
  overflow-y: auto;
}
.join-us-module1 .job-right-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.16rem;
  padding-right: 0.48rem;
}
.join-us-module1 .job-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.48rem;
  border-bottom: 2px solid rgba(28, 41, 71, 0.1);
}
.join-us-module1 .job-name {
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.32rem;
  color: #1c2947;
}
.join-us-module1 .job-email {
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.26rem;
  color: #2352d8;
}
.join-us-module1 .job-cotent {
  padding-top: 0.48rem;
}
.join-us-module1 .job-row-name {
  font-size: 0.2rem;
  font-weight: bold;
  color: #2352d8;
  padding-top: 0.64rem;
}
.join-us-module1 .job-row-name:first-child {
  padding-top: 0;
}
.join-us-module1 .job-row-content {
  padding-top: 0.24rem;
}
.join-us-module1 .job-row-content,
.join-us-module1 .job-row-content p {
  font-size: 0.14rem;
  line-height: 0.24rem;
  color: #1c2947;
}
.join-us-module1 .job-row-content p {
  margin-bottom: 0.16rem;
}
