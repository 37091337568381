.immediately-modal {
  width: 100%;
  height: 1.6rem;
  background-image: url('./images/bgImg.png');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.immediately-modal p {
  margin-right: 0.4rem;
  font-family: SourceHanSansSC-Medium;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.48rem;
  text-align: center;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.immediately-modal .product-btn {
  margin: 0;
}
